import React, { useContext, useEffect, useRef } from "react";
import "./PageLayout.css";
import { PDFDocument } from "pdf-lib";
import LogoOne from "../assets/img/real-time.svg";
import LogoThree from "../assets/img/logo3.png";
import LogoThreeMobile from "../assets/img/logo3-mobile.png";
import LogoFour from "../assets/img/logo4.png";
import MainLogo from "../assets/img/perfectshift-logo.png";
import MainLogoDark from "../assets/img/perfectshift-logo-dark.png";
import Dropdown from "react-bootstrap/Dropdown";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  setBuisnessHeader,
  setRealtimePayPeriod,
  setFilterDateList,
  setPeriodType,
  setUpdateUserData,
  setCostCenterApi,
} from "../store/actions/Compensate";

import MainLogoMobile from "../assets/img/perfectshift-logo-light-mobile.png";
import MainLogoDarkMobile from "../assets/img/perfectshift-logo-dark-mobile.png";

import MenuLight from "../assets/img/menu-dark.png";
import MenuDark from "../assets/img/menu-light.png";
import MissedShift from "../assets/img/missed_shift.png";

import DashboardCounter from "../components/DashboardCounter";
import { useState } from "react";
import { API_CALL_URL_PUBLIC } from "../constants";
import { API_CALL_URL_PRIVATE } from "../constants";
import { DOMAIN_URL } from "../constants/compensate";
import axios from "../axios";
import Iconone from "../assets/img/beds.png";
import Icontwo from "../assets/img/compliance.png";
import Iconthree from "../assets/img/variance.png";
import Iconfour from "../assets/img/hpuoc.png";
import IconfourNew from "../assets/img/houps-new.png";
import MenuIcon1 from "../assets/img/dark.png";

import MenuIcon2 from "../assets/img/setting-light.png";
import MenuIcon22 from "../assets/img/setting-dark.png";

import MenuIcon3 from "../assets/img/logout-light.png";
import MenuIcon33 from "../assets/img/logout-dark.png";

import Submit1 from "../assets/img/send-b.png";
import Submit2 from "../assets/img/send-w.png";

import MenuIcon4 from "../assets/img/dark-mode.png";
import MenuIcon44 from "../assets/img/light-mode.png";
import CPIC from "../assets/img/chat-pic.png";

import Bulb from "../assets/img/info-help.png";
import Help from "../assets/img/helpIcon.png";
import Bulblight from "../assets/img/infolight.png";
import pdf from "../assets/img/pdf.png";
import pdfFile from "../assets/pdf/dummy.pdf";

import Ico7 from "../assets/img/clock-1.png";
import PDF from "../assets/img/REALTIME_QuickTipsV2.pdf";
import * as ST from "../Styled/Styled";

import { useSelector, useDispatch } from "react-redux";

// import  { useLocation } from 'react-router-dom'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MainSideNavbar from "../components/MainSideNavbar";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

import { get, isEmpty } from "lodash";
import { RiSunLine } from "react-icons/ri";
import { MdOutlineLightMode } from "react-icons/md";
import ThemeSwitcher from "../components/ThemeSwitcher";
import { ThemeContext } from "../providers/ThemeProvider";
import { THEME_TYPE } from "../constants/theme";
import Form from "react-bootstrap/Form";
import Arrow from "../assets/img/down.png";
import Arrow1 from "../assets/img/down-black.png";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import DirectEntry from "../pages/DirectEntry/index";
import Button from "@atlaskit/button";
import Overlay from "../components/Overlay";
import { decryptedFunction, dataEnCrypt } from "../utils/helper";
import {
  CustomDropdown,
  DropdownIndicator,
  ChevronDown,
} from "../Styled/DropdownStyle";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PsLogo from "../assets/img/psNew.png";
import ErrorModal from "../components/shared/SuccessModal/ErrorModal";
import Marquee from "react-fast-marquee";
import Schmaintaince from "../assets/img/schmaintaince.png";

export default function PageLayout(props) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const buisnessHeader = useSelector(
    (state) => state.compensate.buisnessHeader
  );
  //const [isVisible, setIsVisible] = useState(false);
  const [isActive, setActive] = useState(false);
  const [isActive1, setActive1] = useState(false);
  // const handleClick = () => setIsVisible(false);
  const [themeMode, setTheme] = useContext(ThemeContext);
  let navigate = useNavigate();
  const boxdata = props.data;
  const isLoader = props.isLoader;
  const costCenterDropdow = props.costCenterDropdow;
  const { isshow } = props;
  const { isCallback } = props;
  const {
    cost_center_number,
    callbackfunc,
    filterdate,
    issearch,
    islogostrip,
    cost_name,
    emergencyVisits,
    uos24Interval,
  } = props;
  const [searchkeyword, setSearchkeyword] = useState("");
  const [maintenanceData, setMaintenanceData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [themeState, setThemeState] = useState("");
  const [getsearchdata, setGetSearchData] = useState([]);
  const [getbulbdata, setGetBulbData] = useState([]);
  const [bulbMessage, setBulbMessage] = useState("");
  const userData = JSON.parse(localStorage.getItem("psdata"));
  const [costCenterList, setCostCenterList] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(undefined);
  const [isDropdownOpenThree, setIsDropdownOpenThree] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [filterDates, setFilterDates] = useState([]);
  const [dropdownValueTwo, setDropdownValueTwo] = useState(undefined);
  const [isDropdownOpentwo, setIsDropdownOpentwo] = useState(false);
  const [dropdownValueThree, setDropdownValueThree] = useState("");
  const isBuisnessUnitSet = localStorage.getItem("buisnessUnit");
  const realPath = pathname?.split("/")[4];
  const costCenterSlug = pathname?.split("/")[3];
  const activeSlug = pathname?.split("/")[1];
  const secondSlug = pathname?.split("/")[2];
  // console.log(userData.data, 'userData');
  
  


  const [userDatas, setUserDatas] = useState(get(userData, "data", {}));
  const userGlobal = useSelector((state) => state.compensate.userData.data);
  const costCenterData = useSelector(
    (state) => state.compensate.costCenterData
  );
  const periodType = useSelector((state) => state.compensate.periodType);

  const costCenterApi = useSelector(
    (state) => state.compensate.costCenterApi
  );

  
  // const walletPath = '/realtime'
  const [hide, setHide] = useState(
    useLocation().pathname.includes("/realtime") ? true : false
  );
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [buisnessData, setBuisnessData] = useState({});
  const [showError, setShowError] = useState(false);
  const [sucessmsg, setSucessmsg] = useState("");

  const [isOpen, setIsOpen] = useState(true);
  const handleTrigger = () => setIsOpen(!isOpen);
  const timeout = userDatas?.customer_masters?.auto_logout_time * 60 * 1000; 

  const buinessUnitDisplay = [
    "home",
    "activedailymanagement",
    "realtime",
    "biweekly",
  ];


  useEffect(() => {
    if (!isEmpty(userData)) {
      const storedTheme = userData.data?.theme_mode;
      if (storedTheme === 1) {
        setTheme({ theme: THEME_TYPE.DARK });
      } else {
        setTheme({ theme: THEME_TYPE.LIGHT });
      }
    }
  }, []);

  const handleThemeChange = async (e) => {
    setIsLoading(true);
    if (themeMode.theme === THEME_TYPE.LIGHT) {
      setTheme({ theme: THEME_TYPE.DARK });
    } else {
      setTheme({ theme: THEME_TYPE.LIGHT });
    }
    // localStorage.setItem("psLang", themeMode.theme);
    const themeType = themeMode.theme === THEME_TYPE.LIGHT ? 1 : 0;

    try {
      const paramData = { theme_mode: themeType };
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/user/updateThemeMode`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      localStorage.setItem("psdata", JSON.stringify(data));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const searchHandler = (e) => {
    e.preventDefault();
    if (isCallback) {
      props.searchcallback(searchkeyword);
    } else {
      searchapi();
    }
    //console.log(e);
  };

  const searchapi = async () => {
    if (searchkeyword != "") {
      let arr = filterdate.split(" ");
      let fromdatearr = arr[0];
      fromdatearr = fromdatearr.split("/");
      const fromdate = `${fromdatearr[1]}-${fromdatearr[0]}-${fromdatearr[2]}`;
      const params = {
        cost_center_number: cost_center_number,
        searchDate: fromdate,
        searchKeyword: searchkeyword,
      };
      const resp = await axios.post(
        API_CALL_URL_PRIVATE + "/department/searchShifts",
        params
      );
      const { data } = resp;
      setGetSearchData(data.data);
    } else {
      setGetSearchData([]);
    }
  };

  const searchkeyup = (e) => {
    const keysearch = e.target.value;
    if (keysearch.length > 2) {
      searchapi();
    } else {
      setGetSearchData([]);
    }
  };

  const searchhandlerbyid = (obj) => {
    obj["id"] = obj._id;
    //e.preventDefault();
    callbackfunc(obj, 1);
    setGetSearchData([]);
    setSearchkeyword("");
  };

  const userLogout = async (logoutType = null) => {
    try {
      const paramData = {
        logout_type: logoutType !== null ? logoutType : undefined,
      };
      const encryptData = dataEnCrypt(paramData);

      const response = await axios.post(`${API_CALL_URL_PRIVATE}/logout`, {
        payloadData: encryptData,
      });
      const data = decryptedFunction(response?.data?.responseData);
      const loginType = userDatas?.login_type;
      window.localStorage.clear();
      window.sessionStorage.clear();
      dispatch(setUpdateUserData(""));
      setTheme({ theme: THEME_TYPE.LIGHT });

      document.title = "PERFECTSHIFT";
      let link = document.querySelector("link[rel~='icon']");
      if (link) {
        link.href = PsLogo;
      }

      if (loginType === 1) {
        const tenantId = "2d4324d6-05ce-4a68-80dd-50c701974fd6";
        const postLogoutRedirectUri = DOMAIN_URL;

        const logoutUrl =
          data?.logoutUrl?.replace("$$", tenantId) +
          `?post_logout_redirect_uri=${encodeURIComponent(
            postLogoutRedirectUri
          )}`;
        window.location.href = logoutUrl;
      }

      if (loginType === 3) {
        const logoutUrl = `https://idp-tuality.ohsu.edu/idp/profile/Logout`;
        window.location.href = logoutUrl;
      }

      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };


   const LogoutNoApI = async () => {
        window.localStorage.clear();
        window.sessionStorage.clear();
        dispatch(setUpdateUserData(""));
        setTheme({ theme: THEME_TYPE.LIGHT });

        document.title = "PERFECTSHIFT";
        let link = document.querySelector("link[rel~='icon']");
        if (link) {
          link.href = PsLogo;
        }

        navigate("/");
   };

  const toggleClass = () => {
    setActive(!isActive);
    let returnData;

    const firstSlug = pathname.split("/")[1];
    if (firstSlug === "home") {
      returnData = 100;
    } else if (firstSlug === "realtime") {
      returnData = 101;
    } else if (firstSlug === "activedailymanagement") {
      returnData = 105;
    } else if (firstSlug === "imaginganalytics") {
      returnData = 108;
    } else if (firstSlug === "edanalytics") {
      returnData = 111;
    } else if (firstSlug === "tune") {
      returnData = 112;
    } else if (firstSlug === "positionmanagement") {
      returnData = 106;
    } else if (firstSlug === "targetmanagement") {
      returnData = 104;
    } else if (firstSlug === "variencemanagement") {
      returnData = 102;
    } else if (firstSlug === "biweeklyreporting") {
      returnData = 103;
    }

    bulbHandle(returnData);
  };
  const toggleClass1 = () => {
    setActive1(!isActive1);
  };

  const bulbHandle = async (bulbId) => {
    try {
      const paramData = {
        appId: bulbId,
        searchKeyword: "",
        sortOnField: "",
      };
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/userManualUtil`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setGetBulbData(data.data);
      setBulbMessage(data?.message);
    } catch (err) {
      console.log(err);
    }
  };

  const myTimeLog = async () => {
    try {
      const paramData = {
        activity_id: 14,
      };
      const encryptData = dataEnCrypt(paramData);
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/user/insertUserLog`,
        { payloadData: encryptData }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,

        color: isFocused ? (themeMode.theme === "DARK" ? "#FFF" : "#000") : "",
        backgroundColor: isFocused
          ? themeMode.theme === "DARK"
            ? "#000"
            : "#efefef"
          : "",
      };
    },
  };

  useEffect(() => {
    activeSlug === "realtime" && getCostCenterList();
    activeSlug === "realtime" && getFilterDate();
     const selectedcostCenter = costCenterList?.find(
       (option) => option?.value === costCenterSlug
     );
     setDropdownValue(selectedcostCenter);
  }, [costCenterSlug]);

  useEffect(() => {
    if (buinessUnitDisplay?.includes(activeSlug)) {
      getBuisnesUnit();
    }
  }, []);

  useEffect(() => {
    setDropdownValueTwo(periodType);
  }, [periodType]);

  const getCostCenterList = async () => {
    try {
      const paramData = {
        businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
      };
      
      const encryptData = dataEnCrypt(paramData);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/general/costCentersLit`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(resp?.data?.responseData);
      console.log("mxy", data?.data?.costCenterResponse);
      
      dispatch(setCostCenterApi(data?.data?.costCenterResponse));
      setCostCenterList(
        data?.data?.costCenterResponse.map((item) => {
          return {
            label: `${
              item.cost_center_number
            } - ${item.cost_center_name?.toUpperCase()}`,
            value: item.slug,
            index: item.cost_center_number,
          };
        })
      );

      const matchingCostCenter = data?.data?.costCenterResponse.find(
        (item) => item.slug === costCenterSlug
      );

      if (matchingCostCenter) {

         setDropdownValue({
           label: `${
             matchingCostCenter.cost_center_number
           } - ${matchingCostCenter.cost_center_name?.toUpperCase()}`,
           value: matchingCostCenter?.slug,
           index: matchingCostCenter.cost_center_number,
         });

        // setSelectedOption(
        //   `${
        //     matchingCostCenter.cost_center_number
        //   } - ${matchingCostCenter.cost_center_name?.toUpperCase()}`
        // );
      } else {
        setDropdownValue("");
      }
      return data?.data?.costCenterResponse;
    } catch (err) {
      return null;
      console.log(err);
    }
  };

  const getFilterDate = async () => {
    try {
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/general/payPeriodDates`
      );
      const data = decryptedFunction(response?.data?.responseData);
      const payPeriods = data?.data?.payPeriodEndDates.map((item, index) => {
        return {
          label: item.display_date,
          value: item.payload_date,
          index: index,
        };
      });
      setFilterDates(payPeriods);
      dispatch(setFilterDateList(payPeriods));


      if(realPath === "currentperiod") {
        const matchingCostCenter = data?.data?.payPeriodEndDates[0];
        setDropdownValueTwo({
          label: matchingCostCenter?.display_date,
          value: matchingCostCenter?.payload_date,
        });
      } else if(realPath === "previousperiods") {
         const matchingCostCenter = data?.data?.payPeriodEndDates[1];
         setDropdownValueTwo({
           label: matchingCostCenter?.display_date,
           value: matchingCostCenter?.payload_date,
         });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const toggleOpenTwo = () => {
    setIsDropdownOpentwo((prevIsOpen) => !prevIsOpen);
  };

  const selectStyles = {
    control: (provided) => ({ ...provided, minWidth: 150, margin: 8 }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
  };

  const toggleOpen = () => {
    setIsDropdownOpen((prevIsOpen) => !prevIsOpen);
  };

  const toggleOpenThree = () => {
    setIsDropdownOpenThree((prevIsOpen) => !prevIsOpen);
  };

  const onSelectChange = (selectedValue) => {
    toggleOpen();
    setDropdownValue(selectedValue);
    navigate(`/realtime/department-details/${selectedValue.value}/${realPath}`);
  };

  const onSelectPayPeriod = (selectedValue) => {
    setDropdownValueTwo(selectedValue);
    if(selectedValue?.index === 0) {
      navigate(`/realtime/department-details/${costCenterSlug}/currentperiod`);
    } else {
      navigate(`/realtime/department-details/${costCenterSlug}/previousperiods`);
    }
  };

  const onRealChange = (selectedValue) => {
    toggleOpen();
    setDropdownValue(selectedValue);
    navigate(`/realtime/department-details/${selectedValue.value}/realtime`);
  };

  useEffect(() => {}, [isDropdownOpen, dropdownValue]);

  const handleInputChange = (inputValue) => {
    props.searchcallback(inputValue);
  };

  const options = [
    { value: "18-01-20024", label: "18-01-20024" },
    { value: "18-01-20024", label: "19-01-20024" },
    { value: "18-01-20024", label: "20-01-20024" },
  ];

  const getBuisnesUnit = async (ccn) => {
    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/BusinessUnitDescriptionList`
      );
      const data = decryptedFunction(resp?.data?.responseData);

      setBuisnessData(
        data?.data?.data?.map((val) => {
          return {
            label: val,
            value: val,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

   const csvHandle = async () => {
     setIsLoading(true);
     try {
       const paramData = {
          cost_center_number: dropdownValue?.index,
        //  cost_center_number: 34000,
         payperiod_end_date: dropdownValueTwo?.value,
       };

       let fileName = `${paramData?.cost_center_number}-${paramData?.payperiod_end_date}.csv`;

       const encryptData = dataEnCrypt(paramData);
       const resp = await axios.post(
         `${API_CALL_URL_PRIVATE}/department/downloadBiweeklySummaryData`,
         { payloadData: encryptData },
         {
           responseType: "blob",
         }
       );

       // Check if the response is a JSON error
       const contentType = resp.headers["content-type"];

       if (contentType.includes("application/json")) {
         // If it's JSON, convert the Blob to JSON
         const reader = new FileReader();
         reader.onload = () => {
          try {
            const errorResponse = JSON.parse(reader.result);
            const { responseData } = errorResponse;
             setShowError(true);
             setSucessmsg(responseData?.message);
             setTimeout(() => {
               setShowError(false);
             }, 2000);

            if (responseData && !responseData.success) {
              console.error("Error message:", responseData.message);
            }
            setIsLoading(false);
          } catch (error) {
            console.error("Error parsing JSON:", error);
            setIsLoading(false);
          }
        };
        reader.readAsText(resp.data);
       } else {
          const blob = resp.data;
          const text = await blob.text();

          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();

          window.URL.revokeObjectURL(url);
          link.remove();
          setIsLoading(false);
       }
     } catch (error) {
       console.error("Error downloading the CSV file: ", error);
        const errorResponse = decryptedFunction(
          error?.response?.data?.responseData
        );
         setShowError(true);
         setSucessmsg(errorResponse?.message);
         setTimeout(() => {
           setShowError(false);
         }, 2000);

       setIsLoading(false);
     }
   };

  const buissnessUnitUpdate = async (value) => {
    try {
      const paramData = {
        business_unit_description: value,
      };
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/businessUnitDescriptionUpdate`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      dispatch(setBuisnessHeader(value));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isBuisnessUnitSet !== null) {
      setDropdownValueThree({
        label: isBuisnessUnitSet,
        value: isBuisnessUnitSet,
      });
    }
  }, [isBuisnessUnitSet]);


  useEffect(() => {
  const fetchCostCenterList = async () => {
    try {
      const costCenterResponse = await getCostCenterList();
      if (
        secondSlug === "department-details" &&
        costCenterResponse?.length > 0
      ) {
        navigate(
          `/realtime/department-details/${costCenterResponse[0]?.slug}/${realPath}`
        );
      }
    } catch (err) {
      console.error("Error fetching cost center list:", err);
    }
  };

  if (mounted) {
    fetchCostCenterList(); // Call the async function
  } else {
    setMounted(true);
  }
  }, [buisnessHeader]);

  const fetchBlobAndOpenPdf = async (link, title) => {
    try {
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/fetch-pdf`,
        { url: link },
        { responseType: "arraybuffer" }
      );

      // Create a Blob from the response data
      const blob = new Blob([response?.data], { type: "application/pdf" });
      const arrayBuffer = await blob.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      // Set custom title
      pdfDoc.setTitle(title);

      // Serialize the PDFDocument to bytes
      const pdfBytes = await pdfDoc.save();

      // Create a new Blob from the updated PDF bytes
      const newBlob = new Blob([pdfBytes], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(newBlob);

      // Open the Blob URL in a new window
      window.open(fileURL, "_blank");

      // Optional: Clean up Blob URL after use
      setTimeout(() => URL.revokeObjectURL(fileURL), 10000); // Revoke after 10 seconds
    } catch (error) {
      console.error("Error fetching the file:", error);
      alert(`Something Went Wrong! ${error.message}`);
    }
  };


  // useEffect(() => {

  //   if (timeout <= 0) return;
  //   let logoutTimer;

  //   // Store the timestamp of the last activity in localStorage
  //   const resetTimer = () => {
  //     if (logoutTimer) clearTimeout(logoutTimer);
  //     localStorage.setItem("lastActivity", Date.now()); // Save the current time as the last activity time
  //     logoutTimer = setTimeout(logoutUser, timeout);
  //   };

  //   // Check if the user has been inactive for too long on component mount
  //   const checkInactivity = () => {
  //     const lastActivity = localStorage.getItem("lastActivity");
  //     const currentTime = Date.now();

  //     if (lastActivity && currentTime - lastActivity > timeout) {
  //       logoutUser();
  //     } else {
  //       resetTimer(); // If user is still within active time, reset the timer
  //     }
  //   };

  //   // Logout function
  //   const logoutUser = () => {
  //     userLogout();
  //   };

  //   // Check inactivity on component mount
  //   checkInactivity();

  //   // Add event listeners for user activity
  //   window.addEventListener("mousemove", resetTimer);
  //   window.addEventListener("keydown", resetTimer);
  //   window.addEventListener("click", resetTimer);

  //   // Cleanup listeners on component unmount
  //   return () => {
  //     window.removeEventListener("mousemove", resetTimer);
  //     window.removeEventListener("keydown", resetTimer);
  //     window.removeEventListener("click", resetTimer);
  //     clearTimeout(logoutTimer);
  //   };
  // }, [navigate]);


  // final
  useEffect(() => {

    // if (process.env.React_App_ENVIRONMENT === "production") {
    //   return;
    // }

    if (timeout <= 0) return;
    let logoutTimer;
    const lastActivityKey = "lastActivity";
    const logoutFlagKey = "isLoggedOut";

    // Store the timestamp of the last activity in localStorage
    const resetTimer = () => {
      if (logoutTimer) clearTimeout(logoutTimer);
      localStorage.setItem(lastActivityKey, Date.now()); // Save the current time as the last activity time
      localStorage.removeItem(logoutFlagKey); // Remove logout flag since activity occurred
      logoutTimer = setTimeout(checkInactivityAcrossTabs, timeout);
    };

    // Check if the user has been inactive for too long across all tabs
    const checkInactivityAcrossTabs = () => {
      const lastActivity = localStorage.getItem(lastActivityKey);
      const currentTime = Date.now();
      const isLoggedOut = localStorage.getItem(logoutFlagKey);

      // Check if the logout flag is set to prevent multiple logout API calls
      if (
        !isLoggedOut &&
        lastActivity &&
        currentTime - lastActivity > timeout
      ) {
        localStorage.setItem(logoutFlagKey, "true");
        logoutUser();
      }
    };

    // Logout function
    const logoutUser = () => {
      userLogout();
    };

    // Check inactivity on component mount
    checkInactivityAcrossTabs();

    // Add event listeners for user activity
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("click", resetTimer);

    // Listen for activity updates across tabs
    const handleStorageChange = (e) => {
      if (e.key === lastActivityKey) {
        resetTimer(); // Reset the timer when activity is detected in another tab
      } else if (e.key === logoutFlagKey && e.newValue === "true") {
        // If another tab has triggered logout, log out this tab too
        LogoutNoApI();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    // Cleanup listeners on component unmount
    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      window.removeEventListener("click", resetTimer);
      window.removeEventListener("storage", handleStorageChange);
      clearTimeout(logoutTimer);
    };
  }, [navigate]);


     useEffect(() => {
       getsettingMaintenance();
     }, []);

     const getsettingMaintenance = async () => {
       try {
         const paramData = {
           key: "maintenance_notification",
         };

        const encryptData = dataEnCrypt(paramData);
        let response = await axios.post(
          `${API_CALL_URL_PRIVATE}/genral/setting`
        );
        const data = decryptedFunction(response?.data?.responseData);
        setMaintenanceData(data);
       } catch (error) {}
     };



    // useEffect(() => {
    //   if (timeout <= 0) return;

    //   let logoutTimer;
    //   const lastActivityKey = "lastActivity";
    //   const logoutFlagKey = "isLoggedOut";
    //   const logoutInitiatorKey = "logoutInitiator"; // New key to track which tab initiated logout

    //   // Store the timestamp of the last activity in localStorage
    //   const resetTimer = () => {
    //     if (logoutTimer) clearTimeout(logoutTimer);
    //     localStorage.setItem(lastActivityKey, Date.now());
    //     localStorage.removeItem(logoutFlagKey);
    //     localStorage.removeItem(logoutInitiatorKey);
    //     logoutTimer = setTimeout(checkInactivityAcrossTabs, timeout);
    //   };

    //   // Generate a unique ID for this tab
    //   const tabId = Math.random().toString(36).substring(7);

    //   // Check if the user has been inactive for too long across all tabs
    //   const checkInactivityAcrossTabs = () => {
    //     const lastActivity = localStorage.getItem(lastActivityKey);
    //     const currentTime = Date.now();
    //     const isLoggedOut = localStorage.getItem(logoutFlagKey);

    //     if (
    //       !isLoggedOut &&
    //       lastActivity &&
    //       currentTime - lastActivity > timeout
    //     ) {
    //       // Set this tab as the initiator of the logout
    //       localStorage.setItem(logoutInitiatorKey, tabId);
    //       localStorage.setItem(logoutFlagKey, "true");

    //       // First tab that detected inactivity uses userLogout
    //       userLogout();
    //     }
    //   };

    //   // Listen for activity updates across tabs
    //   const handleStorageChange = (e) => {
    //     if (e.key === lastActivityKey) {
    //       resetTimer();
    //     } else if (e.key === logoutFlagKey && e.newValue === "true") {
    //       const initiatorId = localStorage.getItem(logoutInitiatorKey);

    //       // If this is not the tab that initiated the logout, use userLogoutNew
    //       if (initiatorId !== tabId) {
    //         userLogoutNew();
    //       }
    //     }
    //   };

    //   // Add event listeners for user activity
    //   window.addEventListener("mousemove", resetTimer);
    //   window.addEventListener("keydown", resetTimer);
    //   window.addEventListener("click", resetTimer);
    //   window.addEventListener("storage", handleStorageChange);

    //   // Initial check and timer setup
    //   checkInactivityAcrossTabs();

    //   // Cleanup
    //   return () => {
    //     window.removeEventListener("mousemove", resetTimer);
    //     window.removeEventListener("keydown", resetTimer);
    //     window.removeEventListener("click", resetTimer);
    //     window.removeEventListener("storage", handleStorageChange);
    //     clearTimeout(logoutTimer);
    //   };
    // }, [navigate]);

    // useEffect(() => {
    //   if (timeout <= 0) return;
    //   let logoutTimer;
    //   const lastActivityKey = "lastActivity";
    //   const logoutFlagKey = "isLoggedOut";
    //   const logoutInitiatorKey = "logoutInitiator";

    //   // Store the timestamp of the last activity in localStorage
    //   const resetTimer = () => {
    //     if (logoutTimer) clearTimeout(logoutTimer);
    //     localStorage.setItem(lastActivityKey, Date.now()); // Save the current time as the last activity time
    //     localStorage.removeItem(logoutFlagKey); // Remove logout flag since activity occurred
    //     localStorage.removeItem(logoutInitiatorKey); // Remove initiator flag as well
    //     logoutTimer = setTimeout(checkInactivityAcrossTabs, timeout);
    //   };

    //   // Check if the user has been inactive for too long across all tabs
    //   const checkInactivityAcrossTabs = () => {
    //     const lastActivity = localStorage.getItem(lastActivityKey);
    //     const currentTime = Date.now();
    //     const isLoggedOut = localStorage.getItem(logoutFlagKey);
    //     const logoutInitiator = localStorage.getItem(logoutInitiatorKey);

    //     // Check if the logout flag is set to prevent multiple logout API calls
    //     if (
    //       !isLoggedOut &&
    //       lastActivity &&
    //       currentTime - lastActivity > timeout
    //     ) {
    //       localStorage.setItem(logoutFlagKey, "true");

    //       // If this tab is the first to trigger logout
    //       if (!logoutInitiator) {
    //         localStorage.setItem(logoutInitiatorKey, "true");
    //         userLogoutMain(); // First tab performs userLogout
    //       }
    //     }
    //   };

    //   // Logout function
    //   const userLogoutMain = () => {
    //     userLogout(); // Perform main logout logic
    //   };

    //   // Check inactivity on component mount
    //   checkInactivityAcrossTabs();

    //   // Add event listeners for user activity
    //   window.addEventListener("mousemove", resetTimer);
    //   window.addEventListener("keydown", resetTimer);
    //   window.addEventListener("click", resetTimer);

    //   // Listen for activity updates across tabs
    //   const handleStorageChange = (e) => {
    //     if (e.key === lastActivityKey) {
    //       resetTimer(); // Reset the timer when activity is detected in another tab
    //     } else if (e.key === logoutFlagKey && e.newValue === "true") {
    //       // If another tab has triggered logout, log out this tab too
    //       const logoutInitiator = localStorage.getItem(logoutInitiatorKey);
    //       if (!logoutInitiator) {
    //         userLogoutNew(); // Other tabs perform userLogoutNew
    //       }
    //     }
    //   };

    //   window.addEventListener("storage", handleStorageChange);

    //   // Cleanup listeners on component unmount
    //   return () => {
    //     window.removeEventListener("mousemove", resetTimer);
    //     window.removeEventListener("keydown", resetTimer);
    //     window.removeEventListener("click", resetTimer);
    //     window.removeEventListener("storage", handleStorageChange);
    //     clearTimeout(logoutTimer);
    //   };
    // }, [timeout]);


  // useEffect(() => {
  //   if (timeout <= 0) return;
  //   let logoutTimer;

  //   // Store the timestamp of the last activity in localStorage
  //   const resetTimer = () => {
  //     if (logoutTimer) clearTimeout(logoutTimer);
  //     localStorage.setItem("lastActivity", Date.now()); // Save the current time as the last activity time
  //     logoutTimer = setTimeout(checkAllTabsInactivity, timeout); // Schedule a check for inactivity
  //   };

  //   // Check if the user has been inactive for too long in all tabs
  //   const checkAllTabsInactivity = () => {
  //     const lastActivity = localStorage.getItem("lastActivity");
  //     const lastActivityOtherTab = localStorage.getItem("globalLastActivity"); // Check the shared global last activity
  //     const currentTime = Date.now();

  //     // If both this tab and the global timestamp exceed the timeout, log out
  //     if (
  //       lastActivity &&
  //       currentTime - lastActivity > timeout &&
  //       lastActivityOtherTab &&
  //       currentTime - lastActivityOtherTab > timeout
  //     ) {
  //       logoutUser();
  //     }
  //   };

  //   // Logout function
  //   const logoutUser = () => {
  //     if (!localStorage.getItem("isLoggedOut")) {
  //       userLogout();
  //       localStorage.setItem("isLoggedOut", "true"); // Ensure logout is done once across tabs
  //     }
  //   };

  //   // Check inactivity on component mount
  //   const checkInactivity = () => {
  //     const lastActivity = localStorage.getItem("lastActivity");
  //     const currentTime = Date.now();

  //     if (lastActivity && currentTime - lastActivity > timeout) {
  //       checkAllTabsInactivity(); // Check inactivity across all tabs
  //     } else {
  //       resetTimer(); // If user is still within active time, reset the timer
  //     }
  //   };

  //   // Broadcast last activity across all tabs using localStorage event
  //   const handleActivityBroadcast = () => {
  //     localStorage.setItem("globalLastActivity", Date.now()); // Update global last activity
  //     resetTimer(); // Reset timer on activity
  //   };

  //   // Listen for activity broadcast from other tabs
  //   const handleStorageEvent = (e) => {
  //     if (e.key === "globalLastActivity") {
  //       resetTimer(); // Reset the timer in this tab if activity is detected in another tab
  //     }
  //     if (e.key === "isLoggedOut" && e.newValue === "true") {
  //       window.localStorage.clear();
  //       window.sessionStorage.clear();
  //       dispatch(setUpdateUserData(""));
  //       setTheme({ theme: THEME_TYPE.LIGHT });
  //       navigate("/");
  //     }
  //   };

  //   // Check inactivity on component mount
  //   checkInactivity();

  //   // Add event listeners for user activity and broadcast to other tabs
  //   window.addEventListener("mousemove", handleActivityBroadcast);
  //   window.addEventListener("keydown", handleActivityBroadcast);
  //   window.addEventListener("click", handleActivityBroadcast);

  //   // Listen for storage events from other tabs
  //   window.addEventListener("storage", handleStorageEvent);

  //   // Cleanup listeners on component unmount
  //   return () => {
  //     window.removeEventListener("mousemove", handleActivityBroadcast);
  //     window.removeEventListener("keydown", handleActivityBroadcast);
  //     window.removeEventListener("click", handleActivityBroadcast);
  //     window.removeEventListener("storage", handleStorageEvent);
  //     clearTimeout(logoutTimer);
  //   };
  // }, [timeout, navigate]);


  // useEffect(() => {
  //   if (timeout <= 0) return;
  //   let logoutTimer;

  //   // Store the timestamp of the last activity in localStorage
  //   const resetTimer = () => {
  //     if (logoutTimer) clearTimeout(logoutTimer);
  //     localStorage.setItem("lastActivity", Date.now()); // Save the current time as the last activity time
  //     logoutTimer = setTimeout(checkAllTabsInactivity, timeout); // Schedule a check for inactivity
  //   };

  //   // Check if the user has been inactive for too long in all tabs
  //   const checkAllTabsInactivity = () => {
  //     const lastActivity = localStorage.getItem("lastActivity");
  //     const lastActivityOtherTab = localStorage.getItem("globalLastActivity"); // Check the shared global last activity
  //     const currentTime = Date.now();

  //     // If both this tab and the global timestamp exceed the timeout, log out
  //     if (
  //       lastActivity &&
  //       currentTime - lastActivity > timeout &&
  //       lastActivityOtherTab &&
  //       currentTime - lastActivityOtherTab > timeout
  //     ) {
  //       logoutUser();
  //     }
  //   };

  //   // Logout function
  //   const logoutUser = () => {
  //     const currentTime = Date.now();
  //     const lastActivity = localStorage.getItem("lastActivity");

  //     // If this tab had the latest activity, call the logout API
  //     if (currentTime - lastActivity <= timeout) {
  //       userLogout(); // Call the logout API in the active tab
  //       localStorage.setItem("isLoggedOut", "true"); // Ensure logout is done once across tabs
  //     } else {
  //       // In other tabs, just navigate to the homepage without calling the API
  //       dispatch(setUpdateUserData(""));
  //       setTheme({ theme: THEME_TYPE.LIGHT });
  //       navigate("/");
  //     }
  //   };

  //   // Check inactivity on component mount
  //   const checkInactivity = () => {
  //     const lastActivity = localStorage.getItem("lastActivity");
  //     const currentTime = Date.now();

  //     if (lastActivity && currentTime - lastActivity > timeout) {
  //       checkAllTabsInactivity(); // Check inactivity across all tabs
  //     } else {
  //       resetTimer(); // If user is still within active time, reset the timer
  //     }
  //   };

  //   // Broadcast last activity across all tabs using localStorage event
  //   const handleActivityBroadcast = () => {
  //     localStorage.setItem("globalLastActivity", Date.now()); // Update global last activity
  //     resetTimer(); // Reset timer on activity
  //   };

  //   // Listen for activity broadcast from other tabs
  //   const handleStorageEvent = (e) => {
  //     if (e.key === "globalLastActivity") {
  //       resetTimer(); // Reset the timer in this tab if activity is detected in another tab
  //     }
  //     if (e.key === "isLoggedOut" && e.newValue === "true") {
  //       // If another tab logs out, log out this tab as well
  //        dispatch(setUpdateUserData(""));
  //        setTheme({ theme: THEME_TYPE.LIGHT });
  //        navigate("/");
  //     }
  //   };

  //   // Check inactivity on component mount
  //   checkInactivity();

  //   // Add event listeners for user activity and broadcast to other tabs
  //   window.addEventListener("mousemove", handleActivityBroadcast);
  //   window.addEventListener("keydown", handleActivityBroadcast);
  //   window.addEventListener("click", handleActivityBroadcast);

  //   // Listen for storage events from other tabs
  //   window.addEventListener("storage", handleStorageEvent);

  //   // Cleanup listeners on component unmount
  //   return () => {
  //     window.removeEventListener("mousemove", handleActivityBroadcast);
  //     window.removeEventListener("keydown", handleActivityBroadcast);
  //     window.removeEventListener("click", handleActivityBroadcast);
  //     window.removeEventListener("storage", handleStorageEvent);
  //     clearTimeout(logoutTimer);
  //   };
  // }, [timeout, navigate]);

  return (
    <>
      {/* <div class="maintenance-banner">
        <div class="banner-left">
          <img src={Schmaintaince} alt="" /> <span>Scheduled Maintenance</span>
        </div>
        <div class="banner-right">
      
          The website will undergo scheduled maintenance on{" "}
            <span style={{ color: "red", padding: "0 5px" }}> 10/17/2024 </span>{" "}
            from <span style={{ color: "red", padding: "0 5px" }}>02:00AM</span>{""}
            to
            <span style={{ color: "red", padding: "0 5px" }}>
              05:00AM PST.
            </span>{" "}
            During this period, you may experience temporary disruptions. We
            apologize for any inconvenience.
        
        </div>
      </div> */}
      <div className="dashboard-wrapper full-width light-mode" id="body">
        {/* -----------------------Sidebar Wrapper-------------------------------- */}
        {/* -----------------------End Sidebar Wrapper-------------------------------- */}
        <div className="w-100">
          {!islogostrip ? (
            <>
              {maintenanceData?.data && (
                <div className="d-flex maintenence-row">
                  <div className="redbox">{maintenanceData?.data?.key}</div>
                  <div
                    className="details-t"
                    dangerouslySetInnerHTML={{
                      __html: maintenanceData?.data?.value,
                    }}
                  ></div>
                </div>
              )}
              <div class="top-bar">
                <div class="container">
                  <div class="row align-items-center">
                    <div class="col col1">
                      <div class="logos">
                        <img
                          onClick={handleTrigger}
                          src={
                            themeMode.theme === "DARK" ? MenuLight : MenuDark
                          }
                          alt=""
                          class="menuicon mobile-only me-2"
                        />
                        {userDatas?.customer_masters?.show_ps_logo === 1 && (
                          <a href="/">
                            <img
                              src={
                                themeMode.theme === "DARK"
                                  ? MainLogoDark
                                  : MainLogo
                              }
                              alt=""
                              class="dekstop-only h-26"
                            />
                            <img
                              src={
                                themeMode.theme === "DARK"
                                  ? MainLogoDarkMobile
                                  : MainLogoMobile
                              }
                              alt=""
                              class="mobile-only"
                            />
                          </a>
                        )}
                      </div>
                    </div>
                    <div class="col col2 text-center">
                      <div class="logos">
                        {/* { !hide ? ( 
                                                <Link to = "/realtime" >
                                                <img src={LogoThree} alt="" />
                                                </Link>
                                                ) : null 
                                    } */}

                        <img
                          // className="dekstop-only"
                          src={
                            themeMode.theme === "DARK"
                              ? userDatas?.customer_masters?.logo_darkmode
                              : userDatas?.customer_masters?.logo_lightmode
                          }
                          alt=""
                        />
                        {/* <img className="mobile-only" src={themeMode.theme === "DARK" ? LogoThreeMobile : LogoThreeMobile} alt="" /> */}
                      </div>
                    </div>

                    <div class="col col3 text-end d-flex align-items-center justify-content-end drop mobile-flex-wrap">
                      {!!userDatas?.show_adm_business_unit_dropdown &&
                        buinessUnitDisplay?.includes(activeSlug) && (
                          <div
                            className=" dekstop-only custom-view-1"
                            style={{ marginRight: "1rem" }}
                          >
                            <div className="custom-select-v cap lt position-relative">
                              <CustomDropdown
                                isDropdownOpen={isDropdownOpenThree}
                                onClose={toggleOpenThree}
                                target={
                                  <OverlayTrigger
                                    placement="left"
                                    overlay={
                                      <Tooltip id="button-tooltip">
                                        Click to select Business Unit
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      iconAfter={<ChevronDown />}
                                      onClick={toggleOpenThree}
                                      isSelected={isDropdownOpenThree}
                                    >
                                      {dropdownValueThree
                                        ? dropdownValueThree.label
                                        : "All"}
                                    </Button>
                                  </OverlayTrigger>
                                }
                              >
                                <Select
                                  autoFocus
                                  backspaceRemovesValue={false}
                                  components={{
                                    DropdownIndicator,
                                    IndicatorSeparator: null,
                                  }}
                                  controlShouldRenderValue={false}
                                  hideSelectedOptions={false}
                                  isClearable={false}
                                  menuIsOpen={isDropdownOpenThree}
                                  onChange={(e) => {
                                    toggleOpenThree();
                                    setDropdownValueThree(e);
                                    buissnessUnitUpdate(e?.value);
                                    localStorage.setItem(
                                      "buisnessUnit",
                                      e?.value
                                    );
                                    // localStorage.setItem("buisnessUnit", e?.value);
                                    // fetchDataRecursive(
                                    //   page,
                                    //   true,
                                    //   null,
                                    //   null,
                                    //   null,
                                    //   e?.value
                                    // );
                                  }}
                                  options={buisnessData}
                                  placeholder="Search..."
                                  styles={selectStyles}
                                  tabSelectsValue={false}
                                  //  value={dropdownValue}
                                  value="All"
                                />
                              </CustomDropdown>
                              {!isEmpty(dropdownValueThree) && (
                                <span
                                  onClick={() => {
                                    localStorage.removeItem("buisnessUnit");
                                    setDropdownValueThree("");
                                    buissnessUnitUpdate(null);
                                    // fetchDataRecursiveClear();
                                  }}
                                  className="custom-cross"
                                  style={{ color: "red", cursor: "pointer" }}
                                >
                                  x
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      {activeSlug === "activedailymanagement" && (
                        <ST.LinkBox className="timeline">
                          <ST.LinkTag
                            href="https://mytime.stanfordmed.org/"
                            target="_blank"
                            className="dp"
                            onClick={() => {
                              myTimeLog();
                            }}
                          >
                            <img
                              src={Ico7}
                              alt=""
                              width={20}
                              style={{ marginRight: "10px" }}
                            />
                            MyTime
                          </ST.LinkTag>
                        </ST.LinkBox>
                      )}

                      {/* RealTime Landing page button/toggle */}
                      {/* {activeSlug === "realtime" && (
                    <Link to={`/realtime-status`}>
                      <ST.LinkBox className="timeline">
                        <ST.LinkTag
                          href="https://mytime.stanfordmed.org/"
                          target="_blank"
                          className="dp"
                          onClick={() => {
                            myTimeLog();
                          }}
                        >
                          Status
                        </ST.LinkTag>
                      </ST.LinkBox>
                    </Link>
                    )} */}

                      {/* <img src={themeMode.theme === "DARK" ? Bulb : Bulblight} alt="" onClick={toggleClass} className={isActive ? 'bulb active' : "bulb"}  /> */}
                      {/* <img src={Help} alt="" onClick={toggleClass} className={isActive ? 'bulb active' : "bulb"} /> */}
                      {pathname !== "/account-settings" &&
                        get(userDatas, "application_accesses", [])
                          ?.filter((inp) => inp.header === 1)
                          .map((val) =>
                            val?.application_id === 199 ? (
                              <img
                                key={val?.application_id}
                                src={val?.logo}
                                className="bulb"
                                onClick={toggleClass}
                              />
                            ) : val?.application_id === 190 ? (
                              activeSlug === "realtime" &&
                              pathname !== "/realtime/status" && (
                                <Link to={`/realtime/status`}>
                                  <ST.LinkBox
                                    style={{ whiteSpace: "nowrap" }}
                                    className="timeline"
                                  >
                                    <ST.LinkTag className="dp">
                                      {val?.title}
                                    </ST.LinkTag>
                                  </ST.LinkBox>
                                </Link>
                              )
                            ) : (
                              <img
                                key={val.application_id}
                                src={val?.defaultLogo} // Default image for other cases
                                className="bulb"
                                onClick={() => navigate(`/${val?.slug}`)}
                              />
                            )
                          )}
                      {/* <div class="logos d-inline-block">
                                        <a href="#"> <img src={LogoThree} alt="" /></a>
                                    </div> */}
                      {/* &nbsp; */}
                      {/* <span class="name text-uppercase">{(get(userDatas, 'short_name', ''))}</span> */}
                      <Dropdown>
                        {/* <Dropdown.Toggle variant="sucess" id="dropdown-basic">

                                                </Dropdown.Toggle> */}
                        <Dropdown.Toggle variant="link" className="user-d">
                          {userGlobal?.profile_image ? (
                            <img
                              className="profile-cover"
                              src={userGlobal?.profile_image}
                            />
                          ) : (
                            <span class="name text-uppercase">
                              {userGlobal?.short_name}
                            </span>
                          )}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {/* <Dropdown.Header align="center"><span class="name">{(get(userDatas, 'first_name', '')).slice(0, 1)}{get(userDatas, 'last_name', '').slice(0, 1)}</span></Dropdown.Header> */}
                          <Dropdown.Header>
                            Hi,{" "}
                            <span className="text-capitalize">
                              {get(userDatas, "employee_name", "")}
                            </span>
                          </Dropdown.Header>
                          <Dropdown.Divider />
                          <Dropdown.Item onClick={() => handleThemeChange()}>
                            {themeMode.theme === "DARK" ? (
                              <span style={{ marginRight: "13px" }}>
                                <RiSunLine style={{ fontSize: "1.5rem" }} />
                              </span>
                            ) : (
                              <img src={MenuIcon1} />
                            )}
                            {themeMode.theme === "DARK"
                              ? "Light Mode"
                              : "Dark Mode"}
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            onClick={() => {
                              myTimeLog();
                            }}
                          >
                            <Link to={`/account-settings`}>
                              <img
                                src={
                                  themeMode.theme === "DARK"
                                    ? MenuIcon22
                                    : MenuIcon2
                                }
                                alt=""
                              />{" "}
                              Settings
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item onClick={() => userLogout(1)}>
                            <img
                              src={
                                themeMode.theme === "DARK"
                                  ? MenuIcon33
                                  : MenuIcon3
                              }
                              alt=""
                            />{" "}
                            Logout
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      {/* <div onClick={handleTrigger} className="toggle-menu-btn">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000" viewBox="0 0 256 256">
                                                <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM40,72H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16ZM216,184H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z"></path>
                                            </svg>
                                        </div> */}
                    </div>

                    <div className="mobile-only all-dropdown custom-view-2">
                      {!!userDatas?.show_adm_business_unit_dropdown &&
                        activeSlug === "activedailymanagement" && (
                          <div
                            className="text-center"
                            style={{ marginRight: "1rem" }}
                          >
                            <div className="custom-select-v cap lt position-relative d-inline-block ">
                              <CustomDropdown
                                isDropdownOpen={isDropdownOpenThree}
                                onClose={toggleOpenThree}
                                target={
                                  <Button
                                    iconAfter={<ChevronDown />}
                                    onClick={toggleOpenThree}
                                    isSelected={isDropdownOpenThree}
                                  >
                                    {dropdownValueThree
                                      ? dropdownValueThree.label
                                      : "All"}
                                  </Button>
                                }
                              >
                                <Select
                                  autoFocus
                                  backspaceRemovesValue={false}
                                  components={{
                                    DropdownIndicator,
                                    IndicatorSeparator: null,
                                  }}
                                  controlShouldRenderValue={false}
                                  hideSelectedOptions={false}
                                  isClearable={false}
                                  menuIsOpen={isDropdownOpenThree}
                                  onChange={(e) => {
                                    toggleOpenThree();
                                    setDropdownValueThree(e);
                                    buissnessUnitUpdate(e?.value);
                                    localStorage.setItem(
                                      "buisnessUnit",
                                      e?.value
                                    );
                                    // localStorage.setItem("buisnessUnit", e?.value);
                                    // fetchDataRecursive(
                                    //   page,
                                    //   true,
                                    //   null,
                                    //   null,
                                    //   null,
                                    //   e?.value
                                    // );
                                  }}
                                  options={buisnessData}
                                  placeholder="Search..."
                                  styles={selectStyles}
                                  tabSelectsValue={false}
                                  //  value={dropdownValue}
                                  value="All"
                                />
                              </CustomDropdown>
                              {!isEmpty(dropdownValueThree) && (
                                <span
                                  onClick={() => {
                                    localStorage.removeItem("buisnessUnit");
                                    setDropdownValueThree("");
                                    buissnessUnitUpdate(null);
                                    // fetchDataRecursiveClear();
                                  }}
                                  className="custom-cross"
                                  style={{ color: "red", cursor: "pointer" }}
                                >
                                  x
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          <div className="container-fluid ps-0 mobile-pe-0  py-0 pb-0 calc-h100">
            <div className="row mobile-m-0">
              {/* <div className={`${hide ? "sidebar--open" : "sidebar--close"}`}  >  <MainSideNavbar/></div> */}
              {/*  <div className={ `pt-3 ${hide ? "width-dec" : "width-in"}`} style={{ marginLeft: '15px'}}> */}

              <div
                className={`sidebar--close ${isOpen ? "" : "sidebar--open"}`}
              >
                {" "}
                <MainSideNavbar />
              </div>
              <div
                className="realtime-page width-in pt-2 tt  llk"
                style={{
                  marginLeft: "3px",
                  overflowY: "hidden",
                  overflowX: "auto",
                }}
              >
                {(realPath === "realtime" ||
                  realPath === "currentperiod" ||
                  realPath === "previousperiods") &&
                  !costCenterDropdow && (
                    <Col md={{ span: 12 }} className="mb-2">
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="ht">
                          <h3 className="text-center color-black m-0">
                            {realPath === "realtime" && "RealTime "}
                            {realPath === "currentperiod" && "Current Period "}
                            {realPath === "previousperiods" &&
                              "Previous Period "}
                            -
                          </h3>
                        </div>
                        <div className="custom-select-v">
                          <CustomDropdown
                            isDropdownOpen={isDropdownOpen}
                            onClose={toggleOpen}
                            target={
                              <Button
                                iconAfter={<ChevronDown />}
                                onClick={toggleOpen}
                                isSelected={isDropdownOpen}
                              >
                                {dropdownValue ? dropdownValue.label : ""}
                              </Button>
                            }
                          >
                            <Select
                              autoFocus
                              backspaceRemovesValue={false}
                              components={{
                                DropdownIndicator,
                                IndicatorSeparator: null,
                              }}
                              controlShouldRenderValue={false}
                              hideSelectedOptions={false}
                              isClearable={false}
                              menuIsOpen={isDropdownOpen}
                              onChange={onSelectChange}
                              options={costCenterList}
                              placeholder="Search..."
                              styles={selectStyles}
                              tabSelectsValue={false}
                              value={dropdownValue}
                            />
                          </CustomDropdown>
                        </div>
                        {realPath !== "realtime" && (
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="custom-select-v">
                              <CustomDropdown
                                isDropdownOpen={isDropdownOpentwo}
                                onClose={toggleOpenTwo}
                                target={
                                  <Button
                                    iconAfter={<ChevronDown />}
                                    onClick={toggleOpenTwo}
                                    isSelected={isDropdownOpentwo}
                                  >
                                    {dropdownValueTwo
                                      ? dropdownValueTwo.label
                                      : ""}
                                  </Button>
                                }
                              >
                                <Select
                                  autoFocus
                                  backspaceRemovesValue={false}
                                  components={{
                                    DropdownIndicator,
                                    IndicatorSeparator: null,
                                  }}
                                  controlShouldRenderValue={false}
                                  hideSelectedOptions={false}
                                  isClearable={false}
                                  menuIsOpen={isDropdownOpentwo}
                                  options={filterDates}
                                  placeholder="Search..."
                                  styles={selectStyles}
                                  tabSelectsValue={false}
                                  onChange={(e) => {
                                    toggleOpenTwo();
                                    onSelectPayPeriod(e);
                                    dispatch(setRealtimePayPeriod(e?.value));
                                    dispatch(setPeriodType(e));
                                  }}
                                  value={dropdownValueTwo}
                                />
                              </CustomDropdown>
                            </div>
                            {!!userDatas?.show_download_csv_button && (
                              <div
                                className={
                                  "flex-grow-1 me-1 border-btn f-14 cursor-pointer green-b active"
                                }
                                style={{ borderColor: "transparent" }}
                                onClick={() => {
                                  csvHandle();
                                }}
                              >
                                Download CSV
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </Col>
                  )}
                {hide
                  ? costCenterApi.length > 0 && (
                      <div class="row header-bar gx-2 align-items-center primary-font 11">
                        <div className="d-flex justify-content-center ">
                          <h4 className="modal-title fw-400 ">{cost_name}</h4>
                        </div>
                        <DashboardCounter
                          isLoader={isLoader}
                          box={"boxone"}
                          icon={Iconone}
                          color={""}
                          emergencyVisits={emergencyVisits}
                          title={
                            boxdata?.census === 1
                              ? costCenterData?.interval === 24
                                ? uos24Interval
                                  ? "UOS"
                                  : "Average Daily UOS"
                                : costCenterData?.cost_center_category === 6
                                ? emergencyVisits
                                  ? "Visits"
                                  : "Average Daily Visits"
                                : "Average Daily Census"
                              : boxdata?.census === 0
                              ? costCenterData?.interval === 24 &&
                                pathname !== "/realtime"
                                ? "UOS"
                                : "Available Beds"
                              : ""
                          }
                          data={boxdata}
                        />
                        <DashboardCounter
                          isLoader={isLoader}
                          box={"boxthree"}
                          icon={Iconthree}
                          color={
                            boxdata?.box3?.total_variance <= 0
                              ? "color-green"
                              : "color-red"
                          }
                          title={"Hours Variance"}
                          data={boxdata?.box3}
                        />
                        {pathname !== "/realtime" && (
                          <DashboardCounter
                            isLoader={isLoader}
                            box={"boxfour"}
                            icon={IconfourNew}
                            color={"color-purple"}
                            title={"HPUOS"}
                            data={boxdata}
                          />
                        )}
                        {pathname === "/realtime" &&
                          boxdata?.productivity_index_status === 1 && (
                            <DashboardCounter
                              isLoader={isLoader}
                              box={"boxfive"}
                              icon={IconfourNew}
                              color={
                                boxdata?.box4?.productivity_index >= 100
                                  ? "color-green"
                                  : "color-red"
                              }
                              title={"Productivity Index"}
                              data={boxdata?.box4}
                            />
                          )}
                        <DashboardCounter
                          issearch={issearch}
                          isLoader={isLoader}
                          box={"boxtwo"}
                          icon={
                            boxdata?.shift === 1
                              ? MissedShift
                              : boxdata?.shift === 0
                              ? Icontwo
                              : MissedShift
                          }
                          color={
                            boxdata?.shift === 1
                              ? boxdata?.box2?.missed_shift <= 0
                                ? "color-green"
                                : "color-red"
                              : boxdata?.shift === 0
                              ? "blue-color"
                              : ""
                          }
                          title={
                            boxdata?.shift === 1
                              ? "Missed Entries"
                              : boxdata?.shift === 0
                              ? "Compliance"
                              : ""
                          }
                          data={boxdata}
                        />
                        {issearch ? (
                          <div class="col">
                            {/* <div className='searchform'>
                                                        <form onSubmit={searchHandler}>
                                                            <input type="text" name="searchfield" value={searchkeyword} onChange={(e) => setSearchkeyword(e.target.value)} onKeyUp={(e) => searchkeyup(e)} autoComplete='off' placeholder="Search here..." class="search-box form-control border-0 " />
                                                        </form>
                                                        <div className='searchwrap'>
                                                            <ul>
                                                                {
                                                                    !isCallback ?
                                                                        getsearchdata.length > 0 ?
                                                                            getsearchdata.map((item, index) => {
                                                                                return (
                                                                                    <li key={index} onClick={() => searchhandlerbyid(item)}>
                                                                                        {item.entered_by} <span>{item.time_stamp} {item.shift_time}</span>
                                                                                        <p>{item.comment}</p>
                                                                                    </li>
                                                                                )
                                                                            }) : null
                                                                        : null
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div> */}
                            <Select
                              inputValue={searchBoxValue}
                              onChange={(e) => {
                                navigate(
                                  `/realtime/department-details/${e.value}/realtime`
                                );
                              }}
                              onInputChange={(inputValue, { action }) => {
                                if (action === "input-change") {
                                  setSearchBoxValue(inputValue);
                                  props.searchcallback(inputValue);
                                }
                              }}
                              options={costCenterList}
                              placeholder={"Search..."}
                              isSearchable
                              onBlurResetsInput={false}
                            />
                          </div>
                        ) : null}
                      </div>
                    )
                  : null}
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-footer white-bg position-relative">
        <div className="d-flex justify-content-center px-3 py-2 align-items-center">
          <div className="d-flex align-items-center">
            {/* <span style={{verticalAlign: 'middle', fontSize: '20px'}}>&copy; </span>  */}
            <small style={{ lineHeight: "21px" }}>
              &copy; {new Date().getFullYear()} PERFECTSHIFT &reg;
            </small>

            {/* <span style={{verticalAlign: 'middle', fontSize: '20px'}}> &reg;</span> */}
          </div>
          {/* <div><img src={MainLogo} alt="" class="h-20" /></div> */}
        </div>
      </div>
      {/* <div id="mySidenav" class="sidenotificationbar" className={isVisible ? 'btndiv' : 'btndivhidden'}> */}
      <div id="mySidenav" className={isActive ? "sidenotificationbar" : null}>
        <h5 className="text-center">Help Center</h5>
        <a href="javascript:void(0)" class="closebtn" onClick={toggleClass}>
          &times;
        </a>
        <div className="notification-sec">
          {!isEmpty(getbulbdata) ? (
            getbulbdata?.map((svc) => (
              <div class="notify-tabs">
                <h5>{svc.title}</h5>
                <p class="greyText">{svc.description}</p>
                <img
                  src={pdf}
                  //  onClick={() => window.open("http://greatwordpresstools.com/pdf/REALTIME_QuickTipsV2.pdf",'_blank' )}
                  onClick={() => {
                    fetchBlobAndOpenPdf(svc.attachment, svc.title);
                  }}
                  alt={svc.attachment}
                  className="pdf new-window"
                />
              </div>
            ))
          ) : (
            <div class="notify-tabs">
              <p class="greyText">{bulbMessage}</p>
            </div>
          )}
        </div>
      </div>
      <div id="mySidenav" className={isActive1 ? "sidemessagebar" : null}>
        <h5 className="text-center">
          Biweekly Summary - 70404 Transport communication CTR
        </h5>
        <div className="chat-date">
          {" "}
          <h5>
            <Select value={selectedOption} options={options} />
          </h5>
        </div>
        <div>
          <hr />
        </div>
        <div className="chat-area">
          <div className="chating-area">
            <div className="chat-perrson-img">
              <img src={CPIC} alt="" className="chat-pic" />
            </div>
            <div className="chat-details">
              <div className="d-flex justify-content-between">
                <div className="chat-person">Cyrus Benjamin</div>
                <div className="chat-dt">27 November 2023 at 11:24</div>
              </div>
              <div className="chat-message">
                When we implemented the small rings, we decided on a simplified
                view (without the 6 smaller boxes). If we are wanting to change
                that now, we should discuss internally before moving this into a
                sprint.
              </div>
            </div>
          </div>
          <div className="chating-area">
            <div className="chat-perrson-img">
              <img src={CPIC} alt="" className="chat-pic" />
            </div>
            <div className="chat-details">
              <div className="d-flex justify-content-between">
                <div className="chat-person">Cyrus Benjamin</div>
                <div className="chat-dt">27 November 2023 at 11:24</div>
              </div>
              <div className="chat-message">
                When we implemented the small rings, we decided on a simplified
                view (without the 6 smaller boxes). If we are wanting to change
                that now, we should discuss internally before moving this into a
                sprint.
              </div>
            </div>
          </div>
          <div className="chating-area">
            <div className="chat-perrson-img">
              <img src={CPIC} alt="" className="chat-pic" />
            </div>
            <div className="chat-details">
              <div className="d-flex justify-content-between">
                <div className="chat-person">Cyrus Benjamin</div>
                <div className="chat-dt">27 November 2023 at 11:24</div>
              </div>
              <div className="chat-message">
                When we implemented the small rings, we decided on a simplified
                view (without the 6 smaller boxes). If we are wanting to change
                that now, we should discuss internally before moving this into a
                sprint.
              </div>
            </div>
          </div>
          <div className="chating-area">
            <div className="chat-perrson-img">
              <img src={CPIC} alt="" className="chat-pic" />
            </div>
            <div className="chat-details">
              <div className="d-flex justify-content-between">
                <div className="chat-person">Cyrus Benjamin</div>
                <div className="chat-dt">27 November 2023 at 11:24</div>
              </div>
              <div className="chat-message">
                When we implemented the small rings, we decided on a simplified
                view (without the 6 smaller boxes). If we are wanting to change
                that now, we should discuss internally before moving this into a
                sprint.
              </div>
            </div>
          </div>
          <div className="chating-area">
            <div className="chat-perrson-img">
              <img src={CPIC} alt="" className="chat-pic" />
            </div>
            <div className="chat-details">
              <div className="d-flex justify-content-between">
                <div className="chat-person">Cyrus Benjamin</div>
                <div className="chat-dt">27 November 2023 at 11:24</div>
              </div>
              <div className="chat-message">
                When we implemented the small rings, we decided on a simplified
                view (without the 6 smaller boxes). If we are wanting to change
                that now, we should discuss internally before moving this into a
                sprint.
              </div>
            </div>
          </div>
          <div className="chating-area">
            <div className="chat-perrson-img">
              <img src={CPIC} alt="" className="chat-pic" />
            </div>
            <div className="chat-details">
              <div className="d-flex justify-content-between">
                <div className="chat-person">Cyrus Benjamin</div>
                <div className="chat-dt">27 November 2023 at 11:24</div>
              </div>
              <div className="chat-message">
                When we implemented the small rings, we decided on a simplified
                view (without the 6 smaller boxes). If we are wanting to change
                that now, we should discuss internally before moving this into a
                sprint.
              </div>
            </div>
          </div>
          <div className="chating-area">
            <div className="chat-perrson-img">
              <img src={CPIC} alt="" className="chat-pic" />
            </div>
            <div className="chat-details">
              <div className="d-flex justify-content-between">
                <div className="chat-person">Cyrus Benjamin</div>
                <div className="chat-dt">27 November 2023 at 11:24</div>
              </div>
              <div className="chat-message">
                When we implemented the small rings, we decided on a simplified
                view (without the 6 smaller boxes). If we are wanting to change
                that now, we should discuss internally before moving this into a
                sprint.
              </div>
            </div>
          </div>
          <div className="chating-area">
            <div className="chat-perrson-img">
              <img src={CPIC} alt="" className="chat-pic" />
            </div>
            <div className="chat-details">
              <div className="d-flex justify-content-between">
                <div className="chat-person">Cyrus Benjamin</div>
                <div className="chat-dt">27 November 2023 at 11:24</div>
              </div>
              <div className="chat-message">
                When we implemented the small rings, we decided on a simplified
                view (without the 6 smaller boxes). If we are wanting to change
                that now, we should discuss internally before moving this into a
                sprint.
              </div>
            </div>
          </div>
        </div>
        <div className="comment-area">
          <div className="chat-g">
            <input type="text" placeholder="Type a message" />

            <Button type="submit">
              <img
                src={themeMode.theme === "DARK" ? Submit2 : Submit1}
                alt=""
                class="submit"
              />
            </Button>
          </div>
        </div>
        <a href="javascript:void(0)" class="closebtn" onClick={toggleClass1}>
          &times;
        </a>
        <div className="notification-sec">
          {getbulbdata?.map((svc) => (
            <div class="notify-tabs">
              <h5>{svc.title}</h5>
              <p class="greyText">{svc.description}</p>
              <img
                src={pdf}
                onClick={() => window.open(svc.attachment)}
                className="pdf"
              />
            </div>
          ))}
        </div>
      </div>
      <div
        className={`sidenavarea ${isOpen ? "" : "active"}`}
        onClick={handleTrigger}
      ></div>
      {isLoading && <Overlay />}
      <ToastContainer />
      <ErrorModal
        isShow={showError}
        // callbackfunc={callBacksuccess}
        title="Error"
        content={sucessmsg}
      />
    </>
  );
}
