import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import "./index.css";
import logo from "../../../assets/img/perfectshift-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import axios from "axios";
import { API_CALL_URL_PUBLIC, DOMAIN_URL } from "../../../constants/compensate";
import { loginSSOValidator } from "../../../utils/validator";
import { decryptedFunction, dataEnCrypt } from '../../../utils/helper';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMsal } from "@azure/msal-react";
import { get, isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { setUpdateUserData } from "../../../store/actions/Compensate";
import { toast, ToastContainer } from "react-toastify";

function Forgot({ history }) {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginValues, setLoginValues] = useState({
    email: "",
  });
  const [maintenanceData, setMaintenanceData] = useState({});
  const [errormsg, setErrormsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const access_token = params?.get("access_token");

  const handleEmail = async (values) => {
    try {
      const paramData = {
        email: values.email,  
      };
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PUBLIC}/authentication/CheckUserSSO`,
        {
          payloadData: encryptData,
        }
      );
      const data = decryptedFunction(response?.data?.responseData);
      if(data?.data?.userData?.login_type === "azure") {
        handleAzure(data?.data?.userData?.sid_email);
      } else if (data?.data?.userData?.login_type === "shibboleth") {
        window.location.href = `${DOMAIN_URL}login`;
      } else {
        return false;
      }
      // history("/reset", { state: data });
    } catch (error) {
      const errorResponse = decryptedFunction(error?.response?.data?.responseData)
      setErrormsg(errorResponse?.message);
      console.log(error);
    }
  };

   const handleAzure = (ssoEmail) => {
     const loginRequest = {
       scopes: ["openid", "profile", "user.read"],
       loginHint: ssoEmail,
     };

     instance
       .loginPopup(loginRequest)
       .then((loginResponse) => {
        const loginData = {
          username: loginResponse?.account.username,
          tenant_id: loginResponse?.account?.tenantId,
          type: 1
        };

         handleLogin(loginData);
       })
       .catch((error) => {
         console.error(error);
       });
   };

    useEffect(() => {
      if (access_token) {
        shibolethLogin(access_token);
      }
    }, [access_token]);


    const shibolethLogin = async (access_token) => {
       const loginData = {
         login_access_token: access_token,
         type: 3,
       };
       handleLogin(loginData);
    };


    const handleLogin = async (loginData) => {
      window.localStorage.clear();
      window.sessionStorage.clear();

      try {
        setIsLoading(true);

        const encryptData = dataEnCrypt(loginData);
        let response = await axios.post(
          `${API_CALL_URL_PUBLIC}/authentication/userLogin`,
          { payloadData: encryptData }
        );
        const data = decryptedFunction(response?.data?.responseData);

        if (!isEmpty(data?.data?.application_accesses)) {
          const urlSlug = data?.data?.application_accesses?.[0]?.slug;
          navigate(`/${urlSlug}`);
        } else {
          navigate("/access-denied");
        }

        localStorage.setItem("accessToken", data?.token?.accessToken);
        localStorage.setItem("psdata", JSON.stringify(data));
        if (data?.data?.business_unit_description !== null) {
          localStorage.setItem(
            "buisnessUnit",
            data?.data?.business_unit_description
          );
        }
        dispatch(setUpdateUserData(data));
        setIsLoading(false);
      } catch (error) {
        const errorResponse = decryptedFunction(
          error?.response?.data?.responseData
        );
        toast.error(`${errorResponse?.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      }
    };


       useEffect(() => {
         getsettingMaintenance();
       }, []);

       const getsettingMaintenance = async () => {
         try {
           const paramData = {
             key: "maintenance_notification",
           };

           const encryptData = dataEnCrypt(paramData);
           let response = await axios.post(
             `${API_CALL_URL_PUBLIC}/public/setting`
           );
           const data = decryptedFunction(response?.data?.responseData);
           setMaintenanceData(data);
         } catch (error) {}
       };


    console.log("domain", `${DOMAIN_URL}login`);
  return (
    <>
      <div class="dashboard-wrapper full-width h-100vh overflow-hidden">
        {maintenanceData?.data && (
          <div
            className="d-flex maintenence-row"
            style={{ backgroundColor: "#FFF", width: "100%" }}
          >
            <div className="redbox">{maintenanceData?.data?.key}</div>
            <div
              className="details-t"
              dangerouslySetInnerHTML={{
                __html: maintenanceData?.data?.value,
              }}
            ></div>
          </div>
        )}
        <div class="container py-3 pb-0 h-100vh ">
          <div class="row height-50 justify-content-center align-items-start h-100vh mt-5">
            <div class="position-absolute top-0 start-0 m-3">
              {!maintenanceData?.data && (
                <Link to={`/`} className="f-14 white-text-dark-mode">
                  <FontAwesomeIcon icon="fa-solid fa-arrow-left " /> Back
                </Link>
              )}
            </div>
            <div class="login-screen max-width-300 ">
              <div class="text-center">
                <img src={logo} alt="" class="img-fluid mb-5" />
              </div>
              <Formik
                enableReinitialize
                initialValues={loginValues}
                validateOnChange
                validate={loginSSOValidator}
                onSubmit={(values) => handleEmail(values, false)}
              >
                {(formikBag) => {
                  return (
                    <Form style={{ width: "100%" }}>
                      <div class="mb-3">
                        <label>Enter your email to continue with SSO.</label>
                        <Field name="email">
                          {({ field }) => (
                            <input
                              {...field}
                              type="username"
                              name="username"
                              class="form-control"
                              id="exampleInputEmail1"
                              placeholder="Email"
                              value={formikBag.values.email}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "email",
                                  e.target.value
                                );
                                setErrormsg("");
                              }}
                            />
                          )}
                        </Field>
                        {formikBag.touched.email && formikBag.errors.email ? (
                          <>
                            <p
                              style={{
                                marginTop: 5,
                                fontSize: 13,
                                color: "red",
                              }}
                            >
                              {formikBag.errors.email}
                            </p>
                          </>
                        ) : null}
                      </div>
                      <div className="col-12 d-flex align-items-center justify-content-left text-danger f-12 mt-1 mb-2">
                        {errormsg}
                      </div>
                      <div class="col-12">
                        <button
                          type="submit"
                          class="btn btm btn-success w-100 text-capitalize"
                          style={{ marginBottom: "8px" }}
                        >
                          Continue
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Forgot;
