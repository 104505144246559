import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useContext } from "react";
import ContentLoader from "react-content-loader";
import { ThemeContext } from "../../providers/ThemeProvider";
import CROSS from "../../assets/img/redcross.png";
import CHECK from "../../assets/img/check.png";
import {
  formatNumberWithFraction,
  formatDecimalTarget,
} from "../../utils/helper";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const LinkLoader = ({ themeMode }) => (
  <div style={{ width: 40, height: 32 }}>
    <ContentLoader
      viewBox="0 0 80 64"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="0" ry="0" width="100" height="100" />
    </ContentLoader>
  </div>
);

const HpousLoader = ({ themeMode }) => (
  <div style={{ width: 40, height: 8 }}>
    <ContentLoader
      viewBox="0 0 80 50"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="0" ry="0" width="100" height="100" />
    </ContentLoader>
  </div>
);

const Loadertwo = () => (
  <div style={{ width: 300, height: 60 }}>
    <ContentLoader viewBox="0 0 100 50">
      {/* Only SVG shapes */}
      <rect x="4" y="4" rx="0" ry="0" width="48" height="4" />
      <rect x="56" y="4" rx="0" ry="0" width="48" height="4" />
      <rect x="4" y="12" rx="0" ry="0" width="48" height="4" />
      <rect x="56" y="12" rx="0" ry="0" width="48" height="4" />
    </ContentLoader>
  </div>
);

function DashboardCounter({
  title,
  icon,
  color,
  box,
  data,
  isLoader,
  isshow,
  issearch,
  emergencyVisits
}) {
  const [themeMode, setTheme] = useContext(ThemeContext);
  const { pathname } = useLocation();
  const costCenterData = useSelector(
    (state) => state.compensate.costCenterData
  );

  let total = null;
  if (box == "boxone") {
    var availble_beds =
      data?.census === 1
        ? costCenterData?.interval === 24
          ? formatNumberWithFraction(data?.box1?.average_daily_uos_24, 1)
          : costCenterData?.cost_center_category === 6
          ? emergencyVisits
            ? formatNumberWithFraction(data?.box1?.average_daily_visits, 0)
            : formatNumberWithFraction(data?.box1?.average_daily_visits, 1)
          : formatNumberWithFraction(data?.box1?.average_daily_census, 1)
        : costCenterData?.interval === 24 && pathname !== "/realtime"
        ? formatNumberWithFraction(data?.box1?.sum_uos_24, 1)
        : formatNumberWithFraction(data?.box1?.availble_beds, 0);
    total = availble_beds;
  } else if (box == "boxtwo") {
    var total_compliance =
      data?.shift === 1
        ? formatNumberWithFraction(data?.box2?.missed_shift, 0)
        : formatNumberWithFraction(data?.box2?.total_compliance, 0);
    total = total_compliance;
  } else if (box == "boxthree") {
    var { total_variance, ...rest } = data ? data : {};
    total = formatNumberWithFraction(total_variance, 1);
  } else if (box == "boxfour") {
    var { total_hpuos, ...rest } = data ? data : {};
    total = formatNumberWithFraction(total_hpuos, 3);
  } else if (box == "boxfive") {
    var { productivity_index, ...rest } = data ? data : {};
    total = `${productivity_index}%`;
  }

  const txtcolor = "d-block fw-600 big-num f-25 lh-1 " + color;

  const styleEl =
    (!issearch && box === "boxtwo") || box === "boxone"
      ? { flex: "1 0%", width: "22.3%", maxWidth: "22.3%" }
      : null;

  return (
    <>
      {box === "boxfour" ? (
        <div class="col llp" style={styleEl}>
          <div class="position-relative  h-100 box-h col-bg">
            <div class="d-flex align-items-center justify-content-between">
              <div class="i d-flex align-items-center">
                <img src={icon} alt="" class="img-fluid" /> &nbsp;
                <div className="position-relative mt-minus-15 min-w-45">
                  {!isLoader ? (
                    <span
                      className={
                        "fw-600 big-num  f-35 lh-1 " +
                        (data?.box4?.hpuos_icon_status === 0
                          ? "red-c"
                          : "green-c")
                      }
                    >
                      {formatNumberWithFraction(data?.box4?.total_hpuos, 3)}
                    </span>
                  ) : (
                    <HpousLoader themeMode={themeMode} />
                  )}
                  <span
                    className={"f-14 color-black fw-400 bottom-text " + color}
                  >
                    {title}
                  </span>
                  &nbsp;{" "}
                  {isshow ? (
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  ) : null}
                </div>
                <div class="" style={{ marginLeft: "-2px", marginTop: "-2px" }}>
                  <div class="pe-0 primary-font ll">
                    <img
                      src={data?.box4?.hpuos_icon_status === 0 ? CROSS : CHECK}
                      alt=""
                      class="img-fluid"
                      style={{ maxHeight: "17px", verticalAlign: "top" }}
                    />
                  </div>
                </div>
                &nbsp;&nbsp;
                <span
                  className="horizontal-line"
                  style={{
                    backgroundColor:
                      themeMode.theme === "DARK" ? "#FFF" : "#4B4D4D",
                  }}
                ></span>
                &nbsp;&nbsp;&nbsp;
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="button-tooltip" className="custom-tooltip">
                      Target: {data?.box4?.total_target}
                    </Tooltip>
                  }
                >
                  <div
                    class="position-relative mt-minus-15 min-w-45"
                    style={{ cursor: "pointer" }}
                  >
                    {!isLoader ? (
                      <span class="fw-600 big-num  f-35 lh-1 ">
                        <i>
                          {" "}
                          {formatDecimalTarget(data?.box4?.total_target)}
                        </i>
                      </span>
                    ) : (
                      <HpousLoader themeMode={themeMode} />
                    )}
                    <span class="f-14 color-black fw-400 bottom-text ">
                      TARGET
                    </span>
                    &nbsp;{" "}
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div class="col llo" style={styleEl}>
          <div class="position-relative  h-100 box-h col-bg">
            <div class="d-flex align-items-center">
              <div class="i d-flex align-items-center">
                <img src={icon} alt="" class="img-fluid" /> &nbsp;
                {!isLoader ? (
                  <span className={"fw-600 big-num  f-35 lh-1 " + color}>
                    {total}
                  </span>
                ) : (
                  <LinkLoader themeMode={themeMode} />
                )}
              </div>
              <div class="ps-2">
                <div class="pe-0 primary-font">
                  <span className={"f-25 color-black fw-600 " + color}>
                    {title}
                  </span>
                  &nbsp;{" "}
                  {isshow ? (
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DashboardCounter;
