
import { Provider } from "react-redux";
import { createMemoryHistory } from "history";
import {
  BrowserRouter,
  Route,
  Routes,
  redirect as Redirect,
  Navigate,
} from "react-router-dom";
import { store } from "./store";
import Home from "./pages/Home/Home";
import LoginPage from "./pages/auth/login/index";
import LoginSSO from "./pages/auth/loginsso/index";
import LoginPsApp from "./pages/auth/LoginPsApp/index";
import DirectEntry from "./pages/DirectEntry/index";
import Applications from "./pages/Applications/index";
import ExceptionsDetails from "./components/ActiveDailyManagementComponents/ExceptionsDetails";
import OvertimeDetails from "./components/ActiveDailyManagementComponents/OvertimeDetails";
import BiweeklyComments from "./components/ActiveDailyManagementComponents/BiweeklyComments";
// import AgencySummary from "./components/ActiveDailyManagementComponents/AgencySummery";
import ExceptionSummary from "./pages/ActiveManagment/ExceptionSummary";
import ActiveDailyManagement from "./pages/ActiveManagment/activedailymangment";
import BiweeklySummary from "./pages/ActiveManagment/BiweeklySummary";
import OvertimeSummary from "./pages/ActiveManagment/OvertimeSummary";
import ScheduleSummary from "./pages/ActiveManagment/Schedule/ScheduleSummary";
import ScheduleDetails from "./pages/ActiveManagment/Schedule/ScheduleDetails";

import MissedMealBreakSummery from "./pages/ActiveManagment/MissedMealBreak/MissedMealBreakSummary"
import MissedMealBreakDetails from "./pages/ActiveManagment/MissedMealBreak/MissedMealBreakDetails"
import AdmNotFound from "./pages/ActiveManagment/AdmNotFound";

import AgencySummery from "./pages/ActiveManagment/Agency/AgencySummary"
import AgencyDetail from "./pages/ActiveManagment/Agency/AgencyDetails"

import VarienceManagement from "./pages/VarienceManagement/index";
import TargetManagement from "./pages/TargetManagement/index";
import PositionManagement from "./pages/PositionManagement/index";
import ImagingAnalytics from "./pages/PbiTools/ImagingAnalytics";
import EdAnalytics from "./pages/PbiTools/EdAnalytics";
import PbITestOne from "./pages/PbiTools/PbITestOne";
import Profile from "./pages/Profile/index";
import ComingSoon from "./pages/ComingSoon/index";
import BiWeeklyReporting from "./pages/BiWeeklyReporting/index";
import AccessDenied from "./pages/AccessDenied/index";
import "./App.css";
import { useEffect, useState, useContext } from "react";
import Forgot from "./pages/auth/forgot";
import Reset from "./pages/auth/reset";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ThemeContext, ThemeProvider } from "./providers/ThemeProvider";
import { Helmet } from "react-helmet";
import ThemeStyle from "./Styled/ThemeStyle";
import { isEmpty } from "lodash";
import RealtimeNew from "./pages/Realtime/realtime";

import Biweekly from "./pages/Biweekly/index";
import CostCenterScorecard from "./pages/Biweekly/CostCenterScoreCard/costcenterscorecard";
import FTETrends from "./pages/Biweekly/Ftetrends";
import StopLoss from "./pages/Biweekly/StopLoss";
import VarianceTrends  from "./pages/Biweekly/VarianceTrends";
import ExcutiveFTEReport from "./pages/Biweekly/ExecutiveFTEReport";
const history = createMemoryHistory();

const PublicRoute = () => {
  let navigate = useNavigate();
  let location = useNavigate();
  const [theme, setTheme] = useState("light");
  return (
    <Routes>
      <Route exact path="/" element={<LoginPage />} />
      <Route
        exact
        path="/forgot"
        element={<Forgot history={navigate} location={location} />}
      />
      <Route
        exact
        path="/reset"
        element={<Reset history={navigate} location={location} />}
      />
      <Route
        exact
        path="/login/sso"
        element={<LoginSSO history={navigate} location={location} />}
      />
      <Route
        exact
        path="/login/oneclicksso"
        element={<LoginPsApp history={navigate} location={location} />}
      />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
};
const PrivateRoute = (userAcces) => {
  const userSlug = !isEmpty(userAcces?.userData?.application_accesses) ? `/${userAcces?.userData?.application_accesses?.[0]?.slug}` : "/access-denied";
  let navigate = useNavigate();
  return (
    <Routes>
      <Route path="/home" element={<Applications />} />
      <Route path="/realtime" element={<Home />} />
      <Route path="/access-denied" element={<AccessDenied />} />
      {/* <Route
        path="/activedailymanagement"
        element={<ActiveManagement history={navigate} />}
      /> */}
      <Route
        path="/activedailymanagement"
        element={<ActiveDailyManagement history={navigate} />}
      />
      <Route
        path="/realtime/status"
        element={<RealtimeNew history={navigate} />}
      />
      <Route
        path="/activedailymanagement/adm-summary/:department"
        element={<BiweeklySummary history={navigate} />}
      />
      <Route
        path="/activedailymanagement/adm-summary/comments"
        element={<BiweeklyComments history={navigate} />}
      />
      <Route
        path="/activedailymanagement/overtime-summary"
        element={<OvertimeSummary history={navigate} />}
      />
      <Route
        path="/activedailymanagement/overtime-summary/overtimedetails/:department"
        element={<OvertimeDetails history={navigate} />}
      />
      {/* ------------------------------------------------- */}
      <Route
        path="/activedailymanagement/schedule-summary"
        element={<ScheduleSummary history={navigate} />}
      />
      <Route
        path="/activedailymanagement/schedule-details/:department"
        element={<ScheduleDetails history={navigate} />}
      />
      {/* ------------------------------------------------- */}
      <Route
        path="/activedailymanagement/missedmealbreak-summary"
        element={<MissedMealBreakSummery history={navigate} />}
      />
      <Route
        path="/activedailymanagement/missedmealbreak-details/:department"
        element={<MissedMealBreakDetails history={navigate} />}
      />
      {/* ------------------------------------------------- */}
      <Route
        path="/activedailymanagement/agency-summary"
        element={<AgencySummery history={navigate} />}
      />
      <Route
        path="/activedailymanagement/agency-details/:department"
        element={<AgencyDetail history={navigate} />}
      />
      {/* ------------------------------------------------- */}
      <Route
        path="/activedailymanagement/exceptions-summary"
        element={<ExceptionSummary history={navigate} />}
      />
      <Route
        path="/biweeklyreporting"
        element={<BiWeeklyReporting history={navigate} />}
      />
      {/* <Route

        path="/activedailymanagement/agency-summary"
        element={<AgencySummary  />}
      /> */}
      <Route
        // path="/activedailymanagement/exceptionsummary/:department/:costid"

        path="/activedailymanagement/exceptionsummary/:department"
        element={<ExceptionsDetails history={navigate} />}
      />

      <Route
        path="/activedailymanagement/not-found"
        element={<AdmNotFound history={navigate} />}
      />

      <Route
        path="/realtime/not-found"
        element={<AdmNotFound history={navigate} />}
      />

      <Route
        path="/realtime/department-details/:idlabel/:urltype"
        element={<DirectEntry />}
      />
      <Route path="/variencemanagement" element={<VarienceManagement />} />
      <Route path="/targetmanagement" element={<TargetManagement />} />
      <Route path="/positionmanagement" element={<PositionManagement />} />

      <Route path="/imaginganalytics" element={<ImagingAnalytics />} />
      <Route path="/edanalytics" element={<EdAnalytics />} />
      <Route path="/tune" element={<PbITestOne />} />

      <Route path="/*" element={<Navigate to={userSlug} />} />
      <Route path="/account-settings" element={<Profile />} />
      <Route path="/coming-soon" element={<ComingSoon />} />

      <Route path="/biweekly" element={<Biweekly history={navigate} />} />
      <Route
        path="/biweekly/costcenterscorecard/:department"
        element={<CostCenterScorecard history={navigate} />}
      />
      <Route
        path="/biweekly/ftetrends/"
        element={<FTETrends history={navigate} />}
      />
      <Route
        path="/biweekly/stoploss/"
        element={<StopLoss history={navigate} />}
      />
      <Route
        path="/biweekly/variancetrends/"
        element={<VarianceTrends history={navigate} />}
      />
      <Route
        path="/biweekly/executive-fte-report/"
        element={<ExcutiveFTEReport history={navigate} />}
      />
    </Routes>
  );
};

const StyleTag = () => {
  const [themeMode, setTheme] = useContext(ThemeContext);
  return (
    <Helmet>
      <style>{ThemeStyle(themeMode.theme)}</style>
    </Helmet>
  );
};

function App() {
  const userData = useSelector((state) => state.compensate.userData?.data);
  const params = new URLSearchParams(window.location.search);
  const access_token = params?.get('access_token');

  return (
    <ThemeProvider>
      <StyleTag />
      <div className="start">
        {(userData && !access_token) ? <PrivateRoute userData={userData} /> : <PublicRoute />}
      </div>
    </ThemeProvider>
  );
}

export default App;
