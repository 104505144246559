import { VALIDATION } from "../constants";
import CryptoJS from 'crypto-js';
import AES from 'crypto-js/aes';
const secretKey = "zxdfgfhrhrshrgtratgrsgrgtztryrty67x"

export const isValidMobile = (mobile) => !VALIDATION || /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/.test(mobile);
export const isValidLandline = (landline) => !VALIDATION || /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/.test(landline);
export const isValidEmail = (email) => !VALIDATION || /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(email);



export const decryptedFunction = (dataToEncrypt) => {
    if (process.env.React_App_ENVIRONMENT == "production") {
      var bytes = CryptoJS.AES.decrypt(dataToEncrypt, secretKey);
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    } else {
        return dataToEncrypt;
    }
};

export const dataEnCrypt = (data) => {
    if (process.env.React_App_ENVIRONMENT == "production") {
    return AES.encrypt(JSON.stringify(data), secretKey).toString()
    } else {
        return data;
    }
}

export const formatDecimalNumber = (num) => {
  return Number?.isInteger(num) ? num?.toFixed(0) : num?.toFixed(2);
};

export const formatDecimalTarget = (num) => {
  return Number?.isInteger(num) ? num?.toFixed(0) : num?.toFixed(2);
};


