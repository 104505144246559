
import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './index.css';

function InfoModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="max-w-620"
    >
      <Modal.Header closeButton className="custom-font-size">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="fw-normal color-black f-18"
        >
         UOS
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-font-size text-indent-15">
      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      </Modal.Body>
    </Modal>
  );
}

export default InfoModal

