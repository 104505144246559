"use strict";
import React, { useEffect, useState, useContext } from "react";
import { AgChartsReact } from "ag-charts-react";
import { Link } from "react-router-dom";
import { getPreviousShiftData } from "../../../services";
import { useSelector, useDispatch } from "react-redux";
import { ThemeContext } from "../../../providers/ThemeProvider";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Coming from "../../../assets/img/comingsoon.png";
// import LineChart from "../../../assets/img/linechart.jpg";
import CensusLineChart from "../../ActiveDailyManagementComponents/shared/CensusLineChart/index";
import AvailableBedsLineChart from "../../ActiveDailyManagementComponents/shared/AvailableBedsLineChart";
import RNAvailableHoursLineChart from "../../ActiveDailyManagementComponents/shared/RNAvailableHoursLineChart";
import GridRNVarianceLineChart from "../../ActiveDailyManagementComponents/shared/GridRNVarianceLineChart";
import EpicScoreLineChart from "../../ActiveDailyManagementComponents/shared/EpicScoreLineChart";

export const ChartExample = ({
  data,
  callbackfunc,
  data1,
  boxColor,
  isdetail,
  directId,
  isCostcenter,
  showCompilanceRing,
  showProductivityRing,
  isDashboard,
  isRealtimeToday,
}) => {
  const costCenterData = useSelector(
    (state) => state.compensate.costCenterData
  );
  const [urltype, setUrltype] = useState("realtime");
  const userData = JSON.parse(localStorage.getItem("psdata"))?.data;
  const [themeMode, setTheme] = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [hide, setHide] = useState(pathname === "/realtime" ? true : false);
  const colorStatus = {
    entry_on_time: "#48b461",
    entry_missing: "#ff2626",
    entry_upcoming: "#d0cece",
    entry_delayed: " #48b461",
    entry_due: "#FFEF00",
  };

  const inner_ring = data?.inner_ring;
  const arr1 = [];
  const arr2 = [];

  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  const [modalShow4, setModalShow4] = React.useState(false);

  function AvailableBedsModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="witoutTitle p-0 height-0">
          <Modal.Title id="contained-modal-title-vcenter"> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 resp">
          <CensusLineChart
            modalGraphData={data1}
            type={"availbleBeds"}
            label={
              userData?.customer_masters?.available_beds_label
                ? userData?.customer_masters?.available_beds_label
                : "Available Beds"
            }
            dataLabel={
              userData?.customer_masters?.available_beds_label
                ? userData?.customer_masters?.available_beds_label
                : "Available Beds"
            }
          />
        </Modal.Body>
      </Modal>
    );
  }
  function CensusdModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="witoutTitle p-0 height-0">
          <Modal.Title id="contained-modal-title-vcenter"> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 resp">
          <CensusLineChart
            modalGraphData={data1}
            type={"census"}
            label={
              userData?.customer_masters?.current_census_label
                ? userData?.customer_masters?.current_census_label
                : "Current Census"
            }
            dataLabel={
              userData?.customer_masters?.current_census_label
                ? userData?.customer_masters?.current_census_label
                : "Current Census"
            }
          />
        </Modal.Body>
      </Modal>
    );
  }
  function RNAvailableHoursModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="witoutTitle p-0 height-0">
          <Modal.Title id="contained-modal-title-vcenter"> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 resp">
          <CensusLineChart
            modalGraphData={data1}
            type={"rnHours"}
            label={
              userData?.customer_masters?.rn_available_hours_label
                ? userData?.customer_masters?.rn_available_hours_label
                : "RN Available Hours"
            }
            dataLabel={
              userData?.customer_masters?.rn_available_hours_label
                ? userData?.customer_masters?.rn_available_hours_label
                : "RN Available Hours"
            }
          />
        </Modal.Body>
      </Modal>
    );
  }
  function GridRNVarianceModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="witoutTitle p-0 height-0">
          <Modal.Title id="contained-modal-title-vcenter"> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 resp">
          <CensusLineChart
            modalGraphData={data1}
            type={"rnVariance"}
            label={
              userData?.customer_masters?.grid_rn_variance_label
                ? userData?.customer_masters?.grid_rn_variance_label
                : "Grid RN Variance"
            }
            dataLabel={
              userData?.customer_masters?.grid_rn_variance_label
                ? userData?.customer_masters?.grid_rn_variance_label
                : "Grid RN Variance"
            }
          />
        </Modal.Body>
      </Modal>
    );
  }
  function EpicScoreModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="witoutTitle p-0 height-0">
          <Modal.Title id="contained-modal-title-vcenter"> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 resp">
          <CensusLineChart
            modalGraphData={data1}
            type={"epicScore"}
            label={
              userData?.customer_masters?.acuity_score_label
                ? userData?.customer_masters?.acuity_score_label
                : "Acuity Score"
            }
            dataLabel={
              userData?.customer_masters?.acuity_score_label
                ? userData?.customer_masters?.acuity_score_label
                : "Acuity Score"
            }
          />
        </Modal.Body>
      </Modal>
    );
  }

  const outerringval = (outer_ring_val) => {
    // let params = 0;
    // let min = -50;
    // let max = 50;
    // let weight = (params.value - min)/(max - min);
    // let lowestScoreRed = 10;
    // let lowestScoreGreen = 157;
    // let lowestScoreBlue  = 44;

    // let highestScoreRed = 10;
    // let highestScoreGreen = 157;
    // let highestScoreBlue = 44;

    // let red = weight * highestScoreRed + (1 - weight) * lowestScoreRed;
    // let green = weight * highestScoreGreen + (1 - weight) * lowestScoreGreen;
    // let blue = weight * highestScoreBlue + (1 - weight) * lowestScoreBlue;

    const seriesarr = [];
    if (outer_ring_val >= 100) {
      arr1.push("#0a9d2c");
      arr1.push("#48b461");
      //arr1.push(`rgb(${red}, ${green}, ${blue})`);
      let caldata = outer_ring_val % 100;
      //setOutervalue(outer_ring_val);
      seriesarr.push({ calval: outer_ring_val, count: caldata });
      seriesarr.push({ calval: outer_ring_val, count: 100 - caldata });
    } else if (outer_ring_val < 100 && outer_ring_val >= 98) {
      arr1.push("#ffff26");
      arr1.push("#ffffb1");
      //setOutervalue(outer_ring_val);
      seriesarr.push({ calval: outer_ring_val, count: outer_ring_val });
      seriesarr.push({ calval: outer_ring_val, count: 100 - outer_ring_val });
    } else {
      arr1.push("#ff2626");
      // arr1.push("#fde9e9"); ////////////////////////////////////////
      arr1.push(themeMode.theme === "DARK" ? "#cdcecf" : "#f1f1f1");
      //setOutervalue(outer_ring_val);
      seriesarr.push({
        name: "Covered",
        calval: outer_ring_val,
        count: outer_ring_val,
      });
      seriesarr.push({
        name: "Not Covered",
        calval: outer_ring_val,
        count: 100 - outer_ring_val,
      });
    }
    return seriesarr;
  };

  const seriesdata = outerringval(data?.outer_ring);

  if (data?.inner_ring) {
    data?.inner_ring.forEach((v) => {
      // if (colorStatus[v.status]) {
      arr2.push(v.color);
    });
  }

  const options = {
    series: [
      {
        type: "pie",

        theme: "ag-default-dark",
        calloutLabelKey: "",
        data: isRealtimeToday
          ? showProductivityRing
            ? seriesdata
            : []
          : seriesdata,
        angleKey: "count",
        outerRadiusRatio:
          isRealtimeToday
           ? showProductivityRing && showCompilanceRing ? 0.83 : 0.72
           : 0.83,
        innerRadiusRatio: 0.58,
        showInLegend: false,
        strokeWidth: 0,

        fills: ["red", "yellow"],
        tooltip: { renderer: renderer1 },
        // rotation:70,
        AgPieSeriesSectorLabelOptions: {
          enabled: true,
        },
        highlightStyle: {
          item: {
            fill: "#CCCCCC00",
            //   stroke: 'maroon',
            strokeWidth: 0,
          },
          series: {
            dimOpacity: 1,
            strokeWidth: 0,
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "70%",
              // background: "#293450",
              borderRadius: 50,
            },
            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15,
              },
              stroke: {
                lineCap: "round",
              },
            },
            dataLabels: {
              name: {
                offsetY: -10,
                color: "#fff",
                // fontSize: "13px",
              },
              value: {
                color: "#fff",
                fontSize: "30px",
                show: true,
              },
            },
            pie: {
              expandOnClick: true,
            },
          },
        },
        // tooltip: {
        //   custom: function({series, seriesIndex, dataPointIndex, w}) {
        //     return '<div class="arrow_box">' +
        //       '<span>' + { renderer: renderer1 } +'</span>' +
        //       '</div>'
        //   }
        // },
        fills: arr1,
      },
      {
        type: "pie",
        data:  isRealtimeToday
          ? showCompilanceRing
            ? inner_ring
            : []
          : inner_ring,
       
        angleKey: "percentage",
        calloutLabelKey: costCenterData?.interval !== 24 && "shift_time",
        calloutLabel: {
          fontSize: 10,
          color: themeMode.theme === "DARK" ? "#FFF" : "black",
          // fontWeight: 'light',
          offsetY: -30,
        },
    
        outerRadiusRatio: 1.1,
        innerRadiusRatio: 0.86,

        showInLegend: false,
        strokes:
          themeMode.theme === "DARK"
            ? ["#191C24", "#191C24", "#191C24", "#191C24", "#191C24", "#191C24"]
            : ["#FFF", "#FFF", "#FFF", "#FFF", "#FFF", "#FFF"],
        strokeWidth: 3,
        listeners: {
          nodeClick: (e) => {
            if (e.datum.status != "entry_upcoming") {
              getPreviousShiftData(
                dispatch,
                directId,
                e.datum.shift_date,
                e.datum.shift_time
              );
              callbackfunc(e.datum, e.datum.id && 1);
            }
          },
        },
        highlightStyle: {
          item: {
            fill: "transparent",
            scale: 1.5,
            strokeOpacity: "#000",

            strokeWidth: 1,
            //  stroke: '#000',
            stroke: themeMode.theme === "DARK" ? "white" : "black",
          },
          series: {
            dimOpacity: 1,
          },
        },

  
        tooltip: { renderer: renderer },
        fills: arr2, // ['red', 'green', 'blue', 'yellow', 'black', 'gray'],
        // fills:  ['red', 'green', 'blue', 'yellow', 'black', 'gray'],
      },
    ],
   
    background: {
      fill: themeMode.theme === "DARK" ? "#191C24" : "white",
    },
  };

  const chartOptions = {
    container: { background: { fill: "yellow" } }, // Set the background color for the chart container
  };

  function renderer(params) {
    //getColorFun(params.color)
    return `<div class="ag-chart-tooltip-title" >
          ${params.datum.message}
        </div>`;
  }

  function renderer1(params) {
    return `<div class="ag-chart-tooltip-title" >
        ${params.datum?.calval}% Productivity
        </div>`;
  }

  return (
    <div
      className={isdetail ? "" : "col-md-3 UU"}
      style={{ backgroundColor: boxColor }}
    >
      {/* {testText+donutColor} */}

      <div
        className={
          isdetail
            ? "white-bg undefined p-0"
            : "white-bg shadow-box undefined p-0"
        }
      >
        <div className=" text-center pt-2 pb-2">
          {!isCostcenter && (
            <strong className="m-f14 primary-font f-25">
              <Link
                className="d-block "
                to={`/realtime/department-details/${data1?.slug}/${urltype}`}
              >
                {" "}
                {data1.cost_center_name?.toUpperCase()}{" "}
              </Link>
            </strong>
          )}

          {!data?.show_compliance_ring &&
          !data?.show_productivity_ring &&
          isRealtimeToday && isDashboard 
          ? (
            <div className="row align-items-center">
              {hide && (
                <div className="col-12 realtime-nodata">
                  <ul
                    className="realtime-details"
                    style={{ textAlign: "center" }}
                  >
                    <li onClick={() => setModalShow1(true)}>
                      <span className="w-40r">
                        {userData?.customer_masters?.current_census_label
                          ? userData?.customer_masters?.current_census_label
                          : "Current Census"}
                        :{" "}
                      </span>
                      <span className="realTimenu">{data?.shift_census}</span>
                    </li>
                    <li onClick={() => setModalShow(true)}>
                      <span className="w-40r">
                        {userData?.customer_masters?.available_beds_label
                          ? userData?.customer_masters?.available_beds_label
                          : "Available Beds"}
                        :{" "}
                      </span>
                      <span className="realTimenu">
                        {data?.shift_available_beds}
                      </span>
                    </li>
                    <li onClick={() => setModalShow2(true)}>
                      <span className="w-40r">
                        {userData?.customer_masters?.rn_available_hours_label
                          ? userData?.customer_masters?.rn_available_hours_label
                          : "RN Available Hours"}
                        :{" "}
                      </span>
                      <span className="realTimenu">
                        {data?.shift_rn_available_hours?.toFixed(2)}
                      </span>
                    </li>
                    <li onClick={() => setModalShow3(true)}>
                      <span className="w-40r">
                        {userData?.customer_masters?.grid_rn_variance_label
                          ? userData?.customer_masters?.grid_rn_variance_label
                          : "Grid RN Variance"}
                        :{" "}
                      </span>
                      <span className="realTimenu">
                        {data?.shift_grid_rn_variance}
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <div className="row align-items-center">
              <div className={hide && "twopx col-7"}>
                <Link
                  className="boxlink"
                  to={`/realtime/department-details/${data1?.slug}/${urltype}`}
                >
                  <AgChartsReact options={options} backgroundColor="yellow" />
                </Link>
              </div>
              {hide && (
                <div className="col-5 width-160">
                  <ul className="realtime-details">
                    <li onClick={() => setModalShow1(true)}>
                      {userData?.customer_masters?.current_census_label
                        ? userData?.customer_masters?.current_census_label
                        : "Current Census"}
                      :{" "}
                      <span className="realTimenumber">
                        {data?.shift_census}
                      </span>
                    </li>
                    <li onClick={() => setModalShow(true)}>
                      {userData?.customer_masters?.available_beds_label
                        ? userData?.customer_masters?.available_beds_label
                        : "Available Beds"}
                      :{" "}
                      <span className="realTimenumber">
                        {data?.shift_available_beds}
                      </span>
                    </li>
                    <li onClick={() => setModalShow2(true)}>
                      {userData?.customer_masters?.rn_available_hours_label
                        ? userData?.customer_masters?.rn_available_hours_label
                        : "RN Available Hours"}
                      :{" "}
                      <span className="realTimenumber">
                        {data?.shift_rn_available_hours?.toFixed(2)}
                      </span>
                    </li>
                    <li onClick={() => setModalShow3(true)}>
                      {userData?.customer_masters?.grid_rn_variance_label
                        ? userData?.customer_masters?.grid_rn_variance_label
                        : "Grid RN Variance"}
                      :{" "}
                      <span className="realTimenumber">
                        {data?.shift_grid_rn_variance}
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <CensusdModal show={modalShow1} onHide={() => setModalShow1(false)} />
      <AvailableBedsModal show={modalShow} onHide={() => setModalShow(false)} />
      <RNAvailableHoursModal
        show={modalShow2}
        onHide={() => setModalShow2(false)}
      />
      <GridRNVarianceModal
        show={modalShow3}
        onHide={() => setModalShow3(false)}
      />
      <EpicScoreModal show={modalShow4} onHide={() => setModalShow4(false)} />
    </div>
  );
};
